<template>
  <div>
    <b-overlay :show="show" no-wrap/>
    <b-row>
      <b-col cols="12" lg="3">
        <b-button
            @click="newConsultations"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Receta
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item
                style="cursor: pointer"
                v-for="(a, idx) in consults"
                :key="idx"
                @click="viewConsultation(a.id)"
            >
              {{ resHoursOdooFormat(a.createdAt) }}(ver archivo)
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="12" lg="9" v-if="createConsultations">
        <h3 style="text-align: center">Receta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input v-model="consul.years" placeholder="Edad"/>
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6"></b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">Dia de Receta</h5>
              <flat-pickr
                  v-model="consul.date_consultation_hours"
                  class="form-control"
                  :config="{
                   enableTime: true,
                   dateFormat: 'Y-m-d H:i:m',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%"/>
            <table>
              <tr>
                <th>Diagnostico</th>
                <th>Acciones</th>
              </tr>
              <tr v-for="(a, idx) in consul.diagnostics">
                <td>
                  <div style="position: relative">
                    <b-form-input
                        placeholder="Diagnostico"
                        v-model="a.product"
                        @keydown="searchDiagnostics(idx, 'create')"
                    />
                    <div
                        v-if="openSelectDiagnostics === idx"
                        style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                    >
                      <p
                          @click="createLineDiagnostics(idx)"
                          style="cursor: pointer; user-select: none"
                      >
                        Crear Linea
                      </p>
                      <div
                          v-if="listDiagnostics.length > 0"
                          v-for="(b, idx2) in listDiagnostics"
                      >
                        <p
                            style="cursor: pointer; user-select: none"
                            @click="selectDiagnosticsList(idx, b.id, b.name)"
                        >
                          {{ b.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <b-button
                      v-if="!a.new_line"
                      @click="addRowDiagnostics(idx, 'create')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Agregar
                  </b-button>
                  <b-button
                      v-else
                      @click="removeDiagnostics(idx, 'create')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Borrar
                  </b-button>
                </td>
              </tr>
            </table>
          </b-col>

          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreateDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreate()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consul.recipes">
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Medicamento"
                            v-model="a.product"
                            @keydown="searchProducts(idx, 'create')"
                        />
                        <div
                            v-if="openSelect === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineProduct(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listProducts.length > 0"
                              v-for="(b, idx2) in listProducts"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectProductList(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Indicaciones"
                            v-model="a.indications"
                            @keydown="searchIndications(idx, 'create')"
                        />
                        <div
                            v-if="openSelectIndications === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineIndications(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear
                          </p>
                          <div
                              v-if="listIndications.length > 0"
                              v-for="(b, idx3) in listIndications"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectIndicationList(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-button
                          v-if="!a.new_line"
                          @click="addRowMedicines(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeLineMedicines(idx, 'create')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-button
                @click="createConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" lg="9" v-if="updateInformation">
        <h3 style="text-align: center">Receta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input v-model="consultations.years" placeholder="Edad"/>
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6"></b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">Dia de Receta</h5>
              <flat-pickr
                  v-model="consultations.date_consultation_hours"
                  class="form-control"
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:m',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%"/>
            <table>
              <tr>
                <th>Diagnostico</th>
                <th>Acciones</th>
              </tr>
              <tr v-for="(a, idx) in consultations.ClinicDiagnostics">
                <td>
                  <div style="position: relative">
                    <b-form-input
                        placeholder="Diagnostico"
                        v-model="a.product"
                        @keydown="searchDiagnostics(idx, 'edit')"
                    />
                    <div
                        v-if="openSelectDiagnostics === idx"
                        style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                    >
                      <p
                          @click="createLineDiagnosticsEdit(idx)"
                          style="cursor: pointer; user-select: none"
                      >
                        Crear Linea
                      </p>
                      <div
                          v-if="listDiagnostics.length > 0"
                          v-for="(b, idx2) in listDiagnostics"
                      >
                        <p
                            style="cursor: pointer; user-select: none"
                            @click="selectDiagnosticsListEdit(idx, b.id, b.name)"
                        >
                          {{ b.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <b-button
                      v-if="!a.new_line"
                      @click="addRowDiagnostics(idx, 'edit')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Agregar
                  </b-button>
                  <b-button
                      v-else
                      @click="removeDiagnostics(idx, 'edit')"
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Borrar
                  </b-button>
                </td>
              </tr>
            </table>
          </b-col>

          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipe()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consultations.ClinicRecipes">
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Medicamento"
                            v-model="a.product"
                            @keydown="searchProducts(idx, 'edit')"
                        />
                        <div
                            v-if="openSelect === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                          "
                        >
                          <p
                              @click="createLineProductEdit(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear producto
                          </p>
                          <div
                              v-if="listProducts.length > 0"
                              v-for="(b, idx2) in listProducts"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectProductListEdit(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style="position: relative">
                        <b-form-input
                            placeholder="Indicaciones"
                            v-model="a.indications"
                            @keydown="searchIndications(idx, 'edit')"
                        />
                        <div
                            v-if="openSelectIndications === idx"
                            style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                        >
                          <p
                              @click="createLineIndicationsEdit(idx)"
                              style="cursor: pointer; user-select: none"
                          >
                            Crear
                          </p>
                          <div
                              v-if="listIndications.length > 0"
                              v-for="(b, idx3) in listIndications"
                          >
                            <p
                                style="cursor: pointer; user-select: none"
                                @click="selectIndicationListEdit(idx, b.id, b.name)"
                            >
                              {{ b.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b-button
                          v-if="!a.new_line"
                          @click="addRowMedicines(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button
                          v-else
                          @click="removeLineMedicines(idx, 'edit')"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-button
                @click="updateConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import logo from "@/assets/images/logo/logo-1.png";
import {
  dateFolio,
  dateToday,
  dateTodayFormat,
  formatHour,
  getFormat,
  sumMonths,
  sumMonthsformat,
  sumMonthsHours
} from "@/utils/date";

export default {
  name: "ConsultationsPatient",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  props: {
    title: String,
    data_patient: Object,
  },
  data() {
    return {
      openSelect: null,
      openSelectIndications: null,
      openSelectDiagnostics: null,
      show: false,
      consultations: {
        id: null,
        height: null,
        weight: null,
        diagnosis: null,
        blood_pressure: null,
        temperature: null,
        body_surface: null,
        years: null,
        heart_rate: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        note_evolution: null,
        imc: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        patient_id: null,
        appointee_id: null,
        date_consultation: null,
        ClinicRecipes: [],
        ClinicDiagnostics: [],
      },
      consults: [],
      listProducts: [],
      listIndications: [],
      listDiagnostics: [],
      patient_id: null,
      print_pdf: false,
      createConsultations: false,
      updateInformation: false,
      logo: require("@/assets/images/logo/logo-black.png"),
      info_img: require("@/assets/images/logo/info.png"),
      consul: {
        height: null,
        weight: null,
        diagnosis: null,
        body_surface: null,
        date_consultation: moment().format("YYYY-MM-DD"),
        date_consultation_hours: dateTodayFormat(),
        blood_pressure: null,
        temperature: null,
        heart_rate: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        imc: null,
        note_evolution: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        patient_id: null,
        appointee_id: null,
        study_field: null,
        study_school: null,
        visa_no: null,
        permit_no: null,
        vat: null,
        name_doctor: null,
        folio: null,
        date_validity: null,
        img_qr: null,
        years: null,
        recipes: [
          {
            recipe_medicines_id: null,
            product: null,
            indications: null,
            new_line: null,
          },
        ],
        diagnostics: [
          {
            diagnostics_list_id: null,
            product: null,
            new_line: null,
          },
        ],
      },
    };
  },
  mounted() {
    this.$root.$on("chargeRecipe", async () => {
      // your code goes here
      this.patient_id = this.data_patient.id;
      this.consul.years = this.data_patient.birthday
          ? this.yearsCalculate(this.data_patient.birthday)
          : "";
      this.consul.study_school = this.getUser.study_school;
      this.consul.visa_no = this.getUser.visa_no;
      this.consul.permit_no = this.getUser.permit_no;
      this.consul.vat = this.getUser.ResPartnerAuth ? this.getUser.ResPartnerAuth?.vat : '';
      this.consul.name_doctor = this.getUser.name;
      this.consul.folio = this.getUser.folio;
      this.consul.study_field = this.getUser.study_field;
      this.consul.img_qr = this.getUser.img_qr;
      await this.getConsultations();
    });
  },
  methods: {
    ...mapActions("patient", [
      "findPatientAllRecipe",
      "createRecipe",
      "findPatientRecipe",
      "updateRecipeStore",
      "findMedicinesSearch",
      "createProductMedicines",
      "generateQrRecipe",
        "findIndicationsSearch",
        "createProductIndications",
        "findPatientOne",
        "getFindFullName"
    ]),
    ...mapActions("studies", [
      "findDiagnostics",
      "createProductDiagnostics"
    ]),
    addRowDiagnostics(idx, type) {
      if (type === "create") {
        this.consul.diagnostics[idx].new_line = true;
        this.consul.diagnostics.push({
          diagnostics_list_id: null,
          product: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicDiagnostics[idx].new_line = true;
        this.consultations.ClinicDiagnostics.push({
          product: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeDiagnostics(id, type) {
      if (type === "create") {
        this.consul.diagnostics.splice(id, 1);
      } else {
        this.consultations.ClinicDiagnostics.splice(id, 1);
      }
    },
    calculateTextareaRows(content) {
      let contentReplace = content
      if (!content){
        contentReplace =  ''

      }
      const lineCount = contentReplace.split('\n').length;
      return Math.max(1, lineCount);
    },
    resizeTextarea(textareaIndex) {
      const textarea = this.$refs[`textarea${textareaIndex}`];
      if (textarea) {
        this.$nextTick(() => {
          textarea.rows = this.calculateTextareaRows(textarea.value);
        });
      }
    },
    async getConsultations() {
      this.consults = await this.findPatientAllRecipe(this.patient_id);
    },

    async selectDiagnosticsList(idx, id, name) {
      this.consul.diagnostics[idx].diagnostics_list_id = id;
      this.consul.diagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },

    async selectDiagnosticsListEdit(idx, id, name) {
      this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = id;
      this.consultations.ClinicDiagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },
    async createLineDiagnostics(idx) {
      if (!this.consul.diagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.openSelectDiagnostics = null;
      this.show = true;
      const send = {
        name: this.consul.diagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consul.diagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineDiagnosticsEdit(idx) {
      if (!this.consultations.ClinicDiagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      this.openSelectDiagnostics = null;
      const send = {
        name: this.consultations.ClinicDiagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchDiagnostics(idx, type) {
      if (type === "create") {
        if (this.consul.diagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consul.diagnostics[idx].product
          );

          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }

        } else {
          this.openSelectDiagnostics = null;
        }
      } else {
        if (this.consultations.ClinicDiagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consultations.ClinicDiagnostics[idx].product
          );
          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectDiagnostics = null;
        }
      }
    },

    async selectProductList(idx, id, name) {
      this.consul.recipes[idx].recipe_medicines_id = id;
      this.consul.recipes[idx].product = name;
      this.openSelect = null;
    },
    async selectIndicationList(idx, id, name) {
      this.consul.recipes[idx].indications = name;
      this.openSelectIndications = null;
    },
    async selectIndicationListEdit(idx, id, name) {
      this.consultations.ClinicRecipes[idx].indications = name;
      this.openSelectIndications = null;
    },

    async selectProductListEdit(idx, id, name) {
      this.consultations.ClinicRecipes[idx].recipe_medicines_id = id;
      this.consultations.ClinicRecipes[idx].product = name;
      this.openSelect = null;
    },
    async createLineIndicationsEdit(idx) {
      if (!this.consultations.ClinicRecipes[idx].indications) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicRecipes[idx].indications,
      };
      const response = await this.createProductIndications(send);
      if (!response.error) {
        this.show = false;
        this.openSelectIndications = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineIndications(idx) {
      if (!this.consul.recipes[idx].indications) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.recipes[idx].indications,
      };
      const response = await this.createProductIndications(send);
      if (!response.error) {
        this.show = false;
        this.openSelectIndications = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProduct(idx) {
      if (!this.consul.recipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.recipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consul.recipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProductEdit(idx) {
      if (!this.consultations.ClinicRecipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicRecipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicRecipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchIndications(idx, type) {
      if (type === "create") {
        if (this.consul.recipes[idx].indications) {
          this.openSelectIndications = idx;
          const response = await this.findIndicationsSearch(
              this.consul.recipes[idx].indications
          );
          this.listIndications = [];
          if (response.indications.length > 0) {
            for (const a of response.indications) {
              this.listIndications.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectIndications= null;
        }
      } else {
        if (this.consultations.ClinicRecipes[idx].indications) {
          this.openSelectIndications = idx;
          const response = await this.findIndicationsSearch(
              this.consultations.ClinicRecipes[idx].indications
          );
          this.listIndications = [];
          if (response.indications.length > 0) {
            for (const a of response.indications) {
              this.listIndications.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectIndications = null;
        }
      }
    },
    async searchProducts(idx, type) {
      if (type === "create") {
        if (this.consul.recipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consul.recipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      } else {
        if (this.consultations.ClinicRecipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consultations.ClinicRecipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      }
    },

    exportPDFRecipeCreate() {
      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(`${this.consul.date_consultation}`, 4, 3);
            doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(`${this.dateStringEs(this.consul.date_consultation)}`, 4, 3);
      if (this.consul.years) {
        doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
      } else {
        doc.text(`Edad:`, 1.5, 3.5);
      }

      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consul.recipes,
      });

      doc.save(`${a}-${b}.pdf`);
    },

    exportPDFRecipe() {
      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(
                `${this.dateStringEs(this.consultations.date_consultation)}`,
                4,
                3
            );
            doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(
          `${this.dateStringEs(this.consultations.date_consultation)}`,
          4,
          3
      );
      doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);
      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consultations.ClinicRecipes,
      });
      /*    console.log(doc.output('datauristring'));return;*/

      doc.save(`${a}-${b}.pdf`);
    },
    newConsultations() {
      this.createConsultations = true;
      this.updateInformation = false;
    },

    async updateConsult() {
      if (this.consultations.ClinicRecipes.length < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `No hay lineas de medicamentos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const response = await this.updateRecipeStore(this.consultations);
      if (response === true) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se edito correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        this.createConsultations = false;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async viewConsultation(id) {
      this.consultations = {};
      this.updateInformation = false;
      this.createConsultations = false;
      this.consultations = await this.findPatientRecipe(id);
      let recipes = this.consultations.ClinicRecipes;
      let diagnostics = this.consultations.ClinicDiagnostics;
      this.consultations.ClinicRecipes = [];
      this.consultations.ClinicDiagnostics = [];
      for (const a of recipes) {
        this.consultations.ClinicRecipes.push({
          product: a.product,
          indications: a.indications,
          recipe_medicines_id: a.recipe_medicines_id,
          clinic_consultations_id: a.clinic_consultations_id,
          new_line: true,
        });
      }
      this.consultations.ClinicRecipes.push({
        product: null,
        indications: null,
        clinic_consultations_id: this.consultations.id,
        new_line: false,
      });

      if (diagnostics.length > 0){
        for (const a of diagnostics) {
          this.consultations.ClinicDiagnostics.push({
            product: a.name,
            diagnostics_list_id: a.diagnostics_list_id,
            clinic_consultations_id: a.clinic_consultations_id,
            new_line: true,
          });
        }

      }
      this.consultations.ClinicDiagnostics.push({
        product: null,
        diagnostics_list_id:null,
        clinic_consultations_id: null,
        new_line: false,
      });
      this.updateInformation = true;
    },

    async createConsult() {
      if (this.consul.recipes.length < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `No hay lineas de medicamentos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      this.consul.appointee_id = this.data_patient.appointee_id;
      this.consul.patient_id = this.data_patient.id;
      this.consul.folio = dateFolio(this.data_patient.id);
      this.consul.date_validity = sumMonthsformat(this.consul.date_consultation, 1);
      const response = await this.createRecipe(this.consul);

      if (response === true) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        this.createConsultations = false;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },

    addRowMedicines(idx, type) {
      if (type === "create") {
        if (!this.consul.recipes[idx].recipe_medicines_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No tiene un medicamento creado o seleccionado`,
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }
        this.consul.recipes[idx].new_line = true;
        this.openSelect = null;
        this.consul.recipes.push({
          product: null,
          indications: null,
          new_line: false,
        });
      } else {
        if (!this.consultations.ClinicRecipes[idx].recipe_medicines_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No tiene un medicamento creado o seleccionado`,
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }
        this.consultations.ClinicRecipes[idx].new_line = true;
        this.consultations.ClinicRecipes.push({
          product: null,
          indications: null,
          recipe_medicines_id: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeLineMedicines(id, type) {
      if (type === "create") {
        this.consul.recipes.splice(id, 1);
      } else {
        this.consultations.ClinicRecipes.splice(id, 1);
      }
    },
    async exportPDFRecipeCreateDigital() {
      this.show = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consul.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consul.date_consultation_hours, 1)}`,
          12.5,
          2.5
      );
      this.consul.folio = dateFolio(this.data_patient.id)
      doc.text(`Folio: ${this.consul.folio ? this.consul.folio :''}`, 6, 3.3);
      doc.text(`${this.consul.name_doctor}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consul.study_field? this.consul.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} / ${this.consul.study_school ? this.consul.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consul.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }


      if (this.consul.diagnostics.length > 0){
        let dataDiagnosis = []
        for (const a of this.consul.diagnostics){
          if (a.product){
            dataDiagnosis.push(a.product)
          }
        }
        if (dataDiagnosis.length > 0){
          let result = dataDiagnosis.map(item => ` - ${item}`).join(',');
          const strArr = doc.splitTextToSize(result ? result : "", 17)
          doc.text('Diagnosticos:', 1, 7.8);
          doc.text(strArr, 1, 8.3);
        }

      }



      let finalY = 9.5;

      doc.autoTable({
        columns: [
          { header: "Medicamento", dataKey: "product" },
          { header: "Indicaciones", dataKey: "indications" },
        ],
        startY: 9.5,
        headStyles: { fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
        bodyStyles: { fontSize: 8 },
        body: this.consul.recipes,
        didDrawPage: function(data) {
          finalY = data.cursor.y;
        },
      });

      if (finalY >= 26.7) {
        doc.addPage();
        finalY = 2;
      }


      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee){
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature){
            finalY += 2;
          }
        }
      }

      const send = {
        folio: this.consul.folio,
        date_consultation: this.consul.date_consultation,
        name_doctor: this.consul.name_doctor,
        permit_no: this.consul.permit_no,
        vat: this.consul.vat,
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(this.consul.name_doctor || '', 8, finalY + 2);
      doc.text("AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México", 6.5, finalY + 9);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, finalY + 3.5, 4, 4);
      console.log(this.getUser)
      if (this.getUser.department_id === 13){


      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        let responseDataSignature = null
        if(this.getUser.id){
          this.show = true
          responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false
        }

        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field? this.consul.study_field : ''} ` ;
          const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no :''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 1, footerPosition - 0.5);
          doc.text(footerText, 1, footerPosition - 1);
          doc.text(footerName, 1, footerPosition-1.5);
          if (responseDataSignature && responseDataSignature.signatureFile){
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 16, footerPosition - 5, 3, 3);
          }
        }
      };

      await addFooters(doc);
      }
      this.show = false;
      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFRecipeDigital() {
      this.show = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consultations.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consultations.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consultations.date_consultation_hours, 1)}`,
          12,
          2.5
      );
      doc.text(`Folio: ${this.consultations.folio ? this.consultations.folio:""}`, 6, 3.3);
      doc.text(`${this.consultations.name_doctor ? this.consultations.name_doctor:""}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consultations.vat ? this.consultations.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consultations.study_field? this.consultations.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consultations.permit_no ? this.consultations.permit_no :''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''} ${this.consultations.study_school ? this.consultations.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consultations.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }

      if (this.consultations.ClinicDiagnostics.length > 0){
        let dataDiagnosis = []
        for (const a of this.consultations.ClinicDiagnostics){
          if (a.product){
              dataDiagnosis.push(a.product)
          }
        }
        if (dataDiagnosis.length > 0){
          let result = dataDiagnosis.map(item => ` - ${item}`).join(',');
          const strArr = doc.splitTextToSize(result ? result : "", 20)
          doc.text('Diagnosticos:', 1, 7.8);
          doc.text(strArr, 1, 8.3);
        }

      }


      let finalY = 9.5;

      doc.autoTable({
        columns: [
          { header: "Medicamento", dataKey: "product" },
          { header: "Indicaciones", dataKey: "indications" },
        ],
        startY: 9.5,
        headStyles: { fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
        bodyStyles: { fontSize: 8 },
        body: this.consultations.ClinicRecipes,
        didDrawPage: function(data) {
          finalY = data.cursor.y;
        },
      });

      if (finalY >= 26.7) {
        doc.addPage();
        finalY = 2;
      }


      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee){
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature){

            finalY += 2;
          }
        }
      }

      const send = {
        folio: this.consultations.folio,
        date_consultation: this.consultations.date_consultation,
        name_doctor: this.consultations.name_doctor,
        permit_no: this.consultations.permit_no,
        vat: this.consultations.vat,
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(this.consultations.name_doctor || '', 8, finalY + 2);
      doc.text("AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México", 6.5, finalY + 9);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, finalY + 3.5, 4, 4);


      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        let responseDataSignature = null
        if(this.consultations.hr_employee_id){
          this.show = true
           responseDataSignature = await this.getFindFullName(this.consultations.hr_employee_id);
          this.show = false
        }


        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.consultations.name_doctor || ''} ${this.consultations.study_field? this.consultations.study_field : ''} ` ;
          const footerText = `Cedula: ${this.consultations.permit_no ? this.consultations.permit_no :''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''} ${this.consultations.study_school ? this.consultations.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 1, footerPosition - 0.5);
          doc.text(footerText, 1, footerPosition - 1);
          doc.text(footerName, 1, footerPosition-1.5);
          if (responseDataSignature && responseDataSignature.signatureFile){
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 16, footerPosition - 5, 3, 3);
          }
        }
      };


      await addFooters(doc);
      this.show = false
      doc.save(`${a}-${b}.pdf`);
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}
</style>
