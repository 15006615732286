<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mt-2 mb-3">
      <b-col
          lg="12"
      >
        <h2 style="text-align: center">{{ title }}</h2>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-3">
      <b-col
          lg="6"
      >

        <b-form @submit.prevent="updateInformation()">
          <b-row>
            <b-col
                lg="12"
            >

              <VoiceInput label="Antecedentes Importantes" placeholder="Antecedentes Importantes" rows="2"
                          id="textarea-1" v-model="general_information.important_background"/>
            </b-col>
            <b-col
                lg="12"
            >
              <b-form-checkbox style="margin-bottom: 20px; margin-top: 20px"
                               @change="changeExtraValue()"
                               v-model="general_information.have_allergies"
                               :value="general_information.have_allergies ? true : ''"
              >
                Alergias negadas
              </b-form-checkbox>

              <VoiceInput v-if="!general_information.have_allergies" label="Alergias" placeholder="Alergias" rows="2"
                          id="textarea-2" v-model="general_information.allergies"/>
            </b-col>
            <b-col
                lg="12"
                class="mt-2"
            >
              <VoiceInput label="Tratamiento Psicológico/Psiquitrico" placeholder="Tratamiento Psicológico/Psiquitrico"
                          rows="2" id="textarea-6"
                          v-model="general_information.psychological_treatment"/>
            </b-col>
            <b-col
                lg="12"
                class="mt-2"
            >
              <VoiceInput label="Quién Refiere al paciente" placeholder="Quién Refiere al paciente" rows="2"
                          id="textarea-3"
                          v-model="general_information.refers_patient"/>
            </b-col>
            <b-col
                lg="12"
                class="mt-2"
            >

              <VoiceInput label="Motivo de la consulta (Según el paciente)"
                          placeholder="Motivo de la consulta (Según el paciente)" rows="2" id="textarea-3"
                          v-model="general_information.reason"/>
            </b-col>
            <b-col
                lg="12"
                class="mt-2"
            >

              <VoiceInput label="Analisis Consulta Primera Vez" placeholder="Analisis Consulta Primera Vez" rows="2"
                          id="textarea-3"
                          v-model="general_information.observations"/>
            </b-col>
            <b-col cols="12" style="margin-top: 10px">
              <div align="right">
                <b-button
                    style="width: 200px"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Guardar datos
                </b-button>
              </div>


            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col
          lg="6"
      >
        <h3 style="text-align: center">Historia Clinica</h3>
        <b-button
            style="width: 200px"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            @click="exportHistoryData"
            variant="primary"
            class="mr-1"
        >
          Imprimir
        </b-button>
        <app-collapse>
          <app-collapse-item active title="Antecedentes Familiares" is-visible>
            <b-row>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Diabetes Mellitus:
                </h4>
                <b-form-radio
                    v-model="history.diabetes_mellitus"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.diabetes_mellitus"
                    :value="false"
                    @change="changeDataCheck('diabetes')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >

                <VoiceInput v-if="history.diabetes_mellitus" label="Diabetes Mellitus" placeholder="Diabetes Mellitus"
                            rows="2" id="textarea-diabetes"
                            v-model="history.diabetes_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cancer:</h4>
                <b-form-radio
                    v-model="history.cancer"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.cancer"
                    :value="false"
                    @change="changeDataCheck('cancer')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >

                <VoiceInput v-if="history.cancer"
                            label="Cancer" placeholder="Cancer" rows="2" id="textarea-cancer-fam"
                            v-model="history.cancer_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hipertensión Arterial:
                </h4>
                <b-form-radio
                    v-model="history.arterial_hypertension"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.arterial_hypertension"
                    :value="false"
                    @change="changeDataCheck('arterial')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >

                <VoiceInput v-if="history.arterial_hypertension"
                            label="Hipertensión Arterial" placeholder="Hipertensión Arterial" rows="2"
                            id="textarea-hiper-fam"
                            v-model="history.arterial_hypertension_observations"/>
              </b-col>
              <b-col
                  lg="12" class="mt-2"
              >
                <h4>Otros antecedentes:
                </h4>
                <VoiceInput label="Otros antecedentes" placeholder="Otros antedecedentes" rows="2" id="textarea-other"
                            v-model="history.other_diseases"/>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item active title="Antecedentes Personales Patológicos">
            <b-row>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Diabetes Mellitus:
                </h4>
                <b-form-radio
                    v-model="history.diatebes_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.diatebes_personal"
                    :value="false"
                    @change="changeDataCheck('diabetesPersonal')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >

                <VoiceInput v-if="history.diatebes_personal"
                            label="Diabetes Mellitus" placeholder="Diabetes Mellitus" rows="2" id="textarea-diabetes-me"
                            v-model="history.diabetes_personal_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cancer:</h4>
                <b-form-radio
                    v-model="history.cancer_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.cancer_personal"
                    :value="false"
                    @change="changeDataCheck('cancerPersonal')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >

                <VoiceInput v-if="history.cancer_personal"
                            label="Cancer" placeholder="Cancer" rows="2" id="textarea-cancer-person"
                            v-model="history.cancer_personal_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cirugías:
                </h4>
                <b-form-radio
                    v-model="history.surgeries"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.surgeries"
                    :value="false"
                    @change="changeDataCheck('surgeries')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >

                <VoiceInput v-if="history.surgeries"
                            label="Cirugías" placeholder="Cirugías" rows="2" id="textarea-cir"
                            v-model="history.surgeries_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hepatitis:
                </h4>
                <b-form-radio
                    v-model="history.hepatitis"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.hepatitis"
                    :value="false"
                    @change="changeDataCheck('hepatitis')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4 v-if="history.hepatitis">Tipo de hepatitis:
                </h4>
                <b-form-select
                    v-if="history.hepatitis"
                    v-model="history.hepatitis_type"
                    :options="options3"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
              <b-col
                  lg="5"
              >
                <VoiceInput v-if="history.hepatitis"
                            label="Hepatitis" placeholder="Hepatitis" rows="2" id="textarea-hepa"
                            v-model="history.hepatitis_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hipertensión Arterial:
                </h4>
                <b-form-radio
                    v-model="history.arterial_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.arterial_personal"
                    :value="false"
                    @change="changeDataCheck('arterialPersonal')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <VoiceInput v-if="history.arterial_personal"
                            label="Hipertensión Arterial" placeholder="Hipertensión Arterial" rows="2"
                            id="textarea-hiper"
                            v-model="history.arterial_personal_observations"/>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Ingesta Actual de Medicamentos:
                </h4>
                <b-form-radio
                    v-model="history.medicines"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.medicines"
                    :value="false"
                    @change="changeDataCheck('medicinas')"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >

                <VoiceInput v-if="history.medicines"
                            label="Ingesta Actual de Medicamentos" placeholder="Ingesta Actual de Medicamentos" rows="2"
                            id="textarea-medical"
                            v-model="history.medicines_observations"/>

              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Grupo sanguineo:
                </h4>
                <b-form-select
                    v-model="history.blood_group"
                    :options="options"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Factor RH:
                </h4>
                <b-form-select
                    v-model="history.rh"
                    :options="options2"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
              <b-col
                  lg="12" class="mt-2"
              >
                <h4>Otros antecedentes personales:
                </h4>
                <VoiceInput label="Otros antecedentes" placeholder="Otros antedecedentes" rows="2" id="textarea-other-personal"
                            v-model="history.other_personal_diseases"/>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Antecedentes Personales NO Patológicos">
            <b-row>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Toma Bebidas Alcohólicas:
                </h4>
                <b-form-radio
                    v-model="history.alcoholism"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.alcoholism"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="4"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.alcoholism"
                    label="Cuanto toma por dia"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.alcoholism_day"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="4"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.alcoholism"
                    label="Cuanto toma por semana"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.alcoholism_week"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Fuma:</h4>
                <b-form-radio
                    v-model="history.smoking"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.smoking"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.smoking"
                    label="Veces que fuma"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.smoking_observations"
                      rows="2"
                      max-rows="999999"
                  />
                </b-form-group>
                <b-form-group
                    v-if="history.smoking"
                    label="Años fumando"

                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder="Años fumando"
                      @keypress="isNumber($event)"
                      @input="formSmokeCalculate"
                      v-model="history.tobacco_years"
                  />
                </b-form-group>
                <b-form-group
                    v-if="history.smoking"
                    label="Cantidad de cigarros al dia"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder="Cantidad de cigarros al dia"
                      @keypress="isNumber($event)"
                      @input="formSmokeCalculate"
                      v-model="history.tobacco_day"
                  />
                </b-form-group>
                <b-form-group
                    v-if="history.smoking"
                    label="Indice tabaquico"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      disabled
                      placeholder=""
                      v-model="totalSmoke"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Drogas:
                </h4>
                <b-form-radio
                    v-model="history.drug_intake"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.drug_intake"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.drug_intake"
                    label="Veces que consume drogas"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.drug_intake_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Ejercicio:
                </h4>
                <b-form-radio
                    v-model="history.exercise"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.exercise"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >

                <VoiceInput v-if="history.exercise"
                            label="Frecuencia de ejercicio" placeholder="Frecuencia de ejercicio" rows="2"
                            id="textarea-frecuency"
                            v-model="history.exercise_observations"/>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Antecedentes Ginecológicos">
            <b-row>
              <b-col
                  lg="12"
              >

                <VoiceInput label="Comentarios" placeholder="Comentarios" rows="2" id="textarea-comments"
                            v-model="history.comments"/>
              </b-col>
              <b-col
                  lg="3"
                  class="mt-2"
              >
                <b-form-group
                    label="Menarca"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.menarche"
                      rows="2"
                  />
                </b-form-group>
                <h4>Gestaciones:
                </h4>
                <b-form-radio
                    v-model="history.gestations"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.gestations"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.gestations">
                <b-col
                    lg="1"
                >
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Partos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.childbirth"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Cesáreas"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.caesarean"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Abortos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.abortions"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Óbitos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.deaths"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Ectópicos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.ectopic"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="5">
                  <b-form-group
                      label="Fecha de ultima gestacion"
                  >
                    <flat-pickr
                        v-model="history.date_gestations"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                        },
                        months: {
                          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">

                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="9">

                  <VoiceInput label="Comentarios" placeholder="Comentarios" rows="2" id="textarea-comments-menarche"
                              v-model="history.comments_menarche"/>

                </b-col>
              </b-row>
              <b-col cols="12">
                <h4>Papanicolau
                </h4>
                <b-form-radio
                    v-model="history.papanicolau"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.papanicolau"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.papanicolau">
                <b-col
                    lg="1"
                >
                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="5">
                  <b-form-group
                      label="Fecha de ultimo papanicolau"
                  >
                    <flat-pickr
                        v-model="history.date_papanicolau"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                        },
                        months: {
                          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">

                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="9">

                  <VoiceInput   label="Comentarios" placeholder="Comentarios" rows="2" id="textarea-pap"
                                v-model="history.comments_papanicolau"/>

                </b-col>
              </b-row>
              <b-col cols="12">
                <h4>Mamografía
                </h4>
                <b-form-radio
                    v-model="history.mammography"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.mammography"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.mammography">
                <b-col
                    lg="1"
                >
                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="5">
                  <b-form-group
                      label="Fecha de ultima mamografía"
                  >
                    <flat-pickr
                        v-model="history.date_mammography"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                        },
                        months: {
                          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">

                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="9">

                  <VoiceInput  label="Comentarios" placeholder="Comentarios" rows="2" id="textarea-comments-mammograhy"
                               v-model="history.comments_mammography"/>

                </b-col>
              </b-row>
              <b-col cols="12">
                <h4>Continúa Menstruando:
                </h4>
                <b-form-radio
                    v-model="history.continue_menstruating"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.continue_menstruating"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.continue_menstruating">
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col
                    lg="4"
                >
                  <b-form-group
                      label="Duración (Días)"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.duration_menstruating"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="4"
                >
                  <b-form-group
                      label="Frecuencia(Días)"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.frequency_menstruating"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col cols="1">

                </b-col>
                <b-col cols="11">
                  <b-form-checkbox
                      v-model="history.irregular_menstrual"
                      :value="history.irregular_menstrual ? true : ''"
                  >
                    Ciclos mentruales irregulares
                  </b-form-checkbox>
                  <b-form-checkbox
                      v-model="history.contraceptives"
                      :value="history.contraceptives ? true : ''"
                  >
                    Anticonceptivos
                  </b-form-checkbox>
                </b-col>
                <b-col cols="3" class="mt-3">

                </b-col>
                <b-col cols="5" class="mt-3">
                  <b-form-group
                      label="Fecha de ultima Regla"
                  >
                    <flat-pickr
                        v-model="history.date_rule"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                        },
                        months: {
                          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        }}}"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row v-else>
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col cols="10">
                  <b-form-group
                      label="Desde cuando"
                  >
                    <flat-pickr
                        v-model="history.date_rule_no"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                        },
                        months: {
                          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        }}}"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-col cols="12" lg="12">
          <b-form-group
              label="Diagnostico ultima consulta"
          >
            <b-form-textarea
                rows="2"
                disabled
                max-rows="999999"
                v-model="note_evolution"
                placeholder="Diagnostico ultima consulta"
            />
          </b-form-group>
        </b-col>


        <b-col cols="12" style="margin-top: 10px">
          <div align="right">

            <b-button
                @click="updateHistory()"
                style="width: 200px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
            >
              Guardar historial
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BFormFile, BForm, BFormSelect, BFormRadio, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import moment from 'moment';
import jsPDF from "jspdf";
import logo from "@/assets/images/logo/logo-1.png";
import {dateFolio, formatHour, getFormat, sumMonthsHours} from "@/utils/date";
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "InformationPatient",
  components: {
    BOverlay,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile, flatPickr, BFormSelect, BFormRadio, ToastificationContent, AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    VoiceInput
  },
  directives: {
    Ripple,
  },
  props: {
    title: String,
    data_patient: Object,
    reload: Function
  },

  data() {
    return {
      create_history: false,
      note_evolution: null,
      show: false,
      options: [
        {value: "", text: 'Selecciona el grupo sanguineo'},
        {value: 'Tipo A', text: 'Tipo A'},
        {value: 'Tipo B', text: 'Tipo B'},
        {value: 'Tipo AB', text: 'Tipo AB'},
        {value: 'Tipo O', text: 'Tipo O'},
        {value: 'N/A', text: 'Lo Desconoce'},
      ],
      options2: [
        {value: "", text: 'Selecciona el factor rh'},
        {value: 'Rh+', text: 'Rh+'},
        {value: 'Rh-', text: 'Rh-'},
      ],
      options3: [
        {value: "", text: 'Selecciona el tipo de hepatitis'},
        {value: 'Hepatitis A', text: 'Hepatitis A'},
        {value: 'Hepatitis B', text: 'Hepatitis B'},
        {value: 'Hepatitis C', text: 'Hepatitis C'},
        {value: 'Hepatitis D', text: 'Hepatitis D'},
        {value: 'Hepatitis E', text: 'Hepatitis E'},
      ],
      general_information: {
        id: '',
        important_background: null,
        allergies: null,
        have_allergies: false,
        refers_patient: null,
        reason: null,
        observations: null,
        psychological_treatment: null,
        patient_id: null,
      },
      totalSmoke: 0,
      history: {
        id: null,
        diabetes_mellitus: false,
        tobacco_years: null,
        tobacco_day: null,
        diabetes_observations: "",
        cancer: false,
        cancer_observations: "",
        date_rule_no: moment().format('YYYY-MM-DD'),
        cancer_personal: false,
        cancer_personal_observations: "",
        arterial_hypertension: false,
        arterial_hypertension_observations: "",
        pathological_observations: "",
        drug_intake: false,
        drug_intake_observations: "",
        surgeries: false,
        surgeries_observations: "",
        transfusions: false,
        transfusions_observations: "",
        hepatitis: false,
        hepatitis_type: "",
        hepatitis_observations: "",
        diatebes_personal: false,
        diabetes_personal_observations: "",
        arterial_personal: false,
        arterial_personal_observations: "",
        other_diseases: "",
        other_personal_diseases: "",
        blood_group: "",
        rh: "",
        smoking: false,
        smoking_observations: "",
        medicines: false,
        medicines_observations: "",
        alcoholism: false,
        alcoholism_week: "",
        alcoholism_day: "",
        exercise: false,
        exercise_observations: "",
        menarche: null,
        gestations: false,
        papanicolau: false,
        mammography: false,
        childbirth: null,
        caesarean: null,
        abortions: null,
        deaths: null,
        ectopic: null,
        date_gestations: moment().format('YYYY-MM-DD'),
        date_papanicolau: moment().format('YYYY-MM-DD'),
        date_mammography: moment().format('YYYY-MM-DD'),
        comments: "",
        comments_menarche: "",
        comments_papanicolau: "",
        comments_mammography: "",
        continue_menstruating: false,
        duration_menstruating: null,
        frequency_menstruating: null,
        irregular_menstrual: false,
        contraceptives: false,
        date_rule: moment().format('YYYY-MM-DD'),
        patient_id: false
      }
    }
  },

  async created() {
    await this.verifyInfo()
    await this.verifyHistory()
    await this.getDiagnosis()
  },
  mounted() {
    this.$root.$on('chargePatientHistory', async () => {
      await this.verifyInfo()
      await this.verifyHistory()
      await this.getDiagnosis()

    })
  },

  methods: {
    ...mapActions('clinic_patient', ['findHistory', 'createHistory']),
    ...mapActions('patient', ['findPatientOneInformation', 'createInformationGeneral', 'updateInformationGeneral', 'findPatientOneHistory', 'createHistoryMedical', 'updateHistoricPatient', 'findOneDescConsultationNote', 'findOneDescConsultation', 'findPatientOne', 'getFindFullName']),
    changeDataCheck(type) {
      switch (type) {
        case 'diabetes':
          this.history.diabetes_observations = ''
          break;
        case 'cancer':
          this.history.cancer_observations = ''
          break;
        case 'arterial':
          this.history.arterial_hypertension_observations = ''
          break;
        case 'diabetesPersonal':
          this.history.diabetes_personal_observations = ''
          break;
        case 'cancerPersonal':
          this.history.cancer_personal_observations = ''
          break;
        case 'surgeries':
          this.history.surgeries_observations = ''
          break;
        case 'hepatitis':
          this.history.hepatitis_observations = ''
          this.history.hepatitis_type = ''
          break;
        case 'arterialPersonal':
          this.history.arterial_personal_observations = ''
          break;
        case 'medicinas':
          this.history.medicines_observations = ''
          break;
        default:
          //este código se ejecutará si ninguno de los casos coincide con la expresión
          break;
      }
    },

    formSmokeCalculate() {
      if (!this.history.tobacco_day) return
      if (!this.history.tobacco_years) return
      this.totalSmoke = this.smokeForm(this.history.tobacco_years, this.history.tobacco_day)

    },
    async getDiagnosis() {
      const response = await this.findOneDescConsultation(this.data_patient.id);
      let resultName = []
      if (response.consultation) {
        if (response.consultation.ClinicDiagnostics.length >= 0) {
          for (const a of response.consultation.ClinicDiagnostics) {
            resultName.push(a.name)
          }
          if (resultName.length >= 0) {
            this.note_evolution = resultName.map(item => `${item}`).join(',');
          }
        }
      }
    },
    async updateInformation() {
      const id = this.general_information.id
      if (this.general_information.have_allergies) {
        this.general_information.allergies = null
      }
      if (!this.general_information.have_allergies) {
        if (!this.general_information.allergies) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'El campo alergias es requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return
        }

      }
      const data = this.general_information
      delete data.id
      delete data.patient_id
      this.show = true;
      const response = await this.updateInformationGeneral({id: id, data: data})
      if (response) {

        await this.verifyInfo()
        await this.verifyHistory();
        await this.reload();
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informacion editada Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar inforamacion',
            icon: 'error',
            variant: 'warning',
          },
        });
      }
    },
    async updateHistory() {
      const id = this.history.id
      const data = this.history
      delete data.id
      delete data.patient_id
      if (this.history.irregular_menstrual === '') {
        this.history.irregular_menstrual = true
      }
      if (this.history.contraceptives === '') {
        this.history.contraceptives = true
      }
      this.show = true;
      const response = await this.updateHistoricPatient({id: id, data: data})
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Historial editado Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })

        await this.verifyHistory()
        this.show = false;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar el historial',
            icon: 'error',
            variant: 'warning',
          },
        });
      }
    },
    changeExtraValue() {
      if (this.general_information.have_allergies === '') {
        this.general_information.have_allergies = true
        return
      }
    },
    async verifyInfo() {
      const response = await this.findPatientOneInformation(this.data_patient.id)
      if (response) {
        this.general_information = response
      } else {
        delete this.general_information.id
        this.general_information.patient_id = this.data_patient.id
        await this.createInformationGeneral(this.general_information);
        await this.verifyInfo()
      }
    },
    async verifyHistory() {
      const response = await this.findPatientOneHistory(this.data_patient.id);
      if (response) {
        if (response.smoking) {
          if (!response.tobacco_day) return
          if (!response.tobacco_years) return
          this.totalSmoke = this.smokeForm(response.tobacco_years, response.tobacco_day)
        }
        this.history = response
      } else {
        delete this.history.id
        this.history.patient_id = this.data_patient.id;
        await this.createHistoryMedical(this.history);
        await this.verifyHistory()
      }
    },
    async exportHistoryData() {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Historia Clinica`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(`Nombre Paciente:`, 9, 2.5);
      doc.text(`${this.data_patient.name}`, 9, 3);
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 1.5, 4.3);
      doc.text(
          `RFC: ${
              this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth?.vat : ""
          }`,
          12,
          4.3
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 1.5, 5);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1.5, 5.8);

      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 16, 2.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 16, 3);
      } else {
        doc.text(`Fecha de nacimiento:`, 16, 2.5);
      }

      if (this.data_patient.birthday) {
        doc.text(`Años: ${this.data_patient.birthday ? `${this.yearsCalculate(this.data_patient.birthday)} Años` : ""}`, 16, 1.5);
      }
      doc.setFontSize(13);
      doc.text(`Antecedentes Familiares`, 8, 6.5);
      let sufferings = [];
      doc.setFontSize(10);
      sufferings.push({
        response: this.history.diabetes_mellitus ? 'Si' : 'No',
        suffering: 'Diabetes',
        description: this.history.diabetes_observations,
      })
      sufferings.push({
        response: this.history.cancer ? 'Si' : 'No',
        suffering: 'Cancer',
        description: this.history.cancer_observations,
      })
      sufferings.push({
        response: this.history.arterial_hypertension ? 'Si' : 'No',
        suffering: 'Hipertensión Arterial',
        description: this.history.arterial_hypertension_observations,
      })
      const margin = 1.5;

      let tableHeight = margin;
      sufferings.forEach(suffering => {
        tableHeight += 1;
      });

      const startY = 3 + tableHeight;
      doc.autoTable({
        columns: [
          {header: "Padecimiento", dataKey: "suffering"},
          {header: "Respuesta", dataKey: "response"},
          {header: "Descripción", dataKey: "description"},
        ],
        startY: startY,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: sufferings,
      })
      doc.setFontSize(10);
      let currentHeight = startY + (margin + 2);

      if (this.history.other_diseases) {
        doc.text("Otros antecedentes:", 1.5, startY + (margin + 2));
        const strArr = doc.splitTextToSize(this.history.other_diseases, 18)
        currentHeight += 0.5;
        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
            currentHeight = 2;
          }
          doc.text(line, 1.5, currentHeight);
          currentHeight += 0.7;
        });
      }
      if (this.history.other_personal_diseases) {
        doc.text("Otros antecedentes personales:", 1.5, startY + (margin + 2));
        const strArr = doc.splitTextToSize(this.history.other_personal_diseases, 18)
        currentHeight += 0.5;
        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
            currentHeight = 2;
          }
          doc.text(line, 1.5, currentHeight);
          currentHeight += 0.7;
        });
      }
      const heightSum = currentHeight + 2
      doc.setFontSize(13);
      doc.text("Antecedentes Personales Patológicos:", 7, heightSum);
      doc.setFontSize(10);
      let personal = []
      personal.push({
        response: this.history.diatebes_personal ? 'Si' : 'No',
        suffering: 'Diabetes',
        description: this.history.diabetes_personal_observations,
        type: '-----',
      })
      personal.push({
        response: this.history.cancer_personal ? 'Si' : 'No',
        suffering: 'Cancer',
        description: this.history.cancer_personal_observations,
        type: '-----',
      })
      personal.push({
        response: this.history.hepatitis ? 'Si' : 'No',
        suffering: 'Hepatitis',
        description: this.history.hepatitis_observations,
        type: this.history.hepatitis_type,
      })
      personal.push({
        response: this.history.surgeries ? 'Si' : 'No',
        suffering: 'Cirugías',
        description: this.history.surgeries_observations,
        type: '-----',
      })
      personal.push({
        response: this.history.arterial_hypertension ? 'Si' : 'No',
        suffering: 'Hipertensión Arterial',
        description: this.history.arterial_personal_observations,
        type: '-----',
      })
      personal.push({
        response: this.history.medicines ? 'Si' : 'No',
        suffering: 'Ingesta de medicamentos',
        description: this.history.medicines_observations,
        type: '-----',
      })
      let tableHeightPersonal = heightSum;

      const startYPersonal = 0.5 + tableHeightPersonal;
      doc.autoTable({
        columns: [
          {header: "Padecimiento", dataKey: "suffering"},
          {header: "Respuesta", dataKey: "response"},
          {header: "Descripción", dataKey: "description"},
          {header: "Tipo", dataKey: "type"},
        ],
        startY: startYPersonal,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: personal,
        didDrawPage: function (data) {
          tableHeightPersonal = data.cursor.y;
        },
      })

      currentHeight += 4 + 0.5;
      if (this.checkAndAddNewPageHistory(tableHeightPersonal, pageHeight, doc)) {
        tableHeightPersonal = 2;
      }
      const table2Height = tableHeightPersonal + 1;
      doc.text("Grupo sanguineo:", 1.5, table2Height);
      doc.text(this.history.blood_group, 1.5, table2Height + 0.5);
      doc.text("Factor RH:", 5, table2Height);
      doc.text(this.history.rh, 5, table2Height + 0.5);
      doc.setFontSize(13);
      doc.text("Antecedentes Personales NO Patológicos:", 7, table2Height + 1.5);
      doc.setFontSize(10);
      doc.text(`Toma Bebidas Alcohólicas: ${this.history.alcoholism ? 'Si' : 'No'}`, 1.5, table2Height + 2.5);
      doc.text(`Cuanto topa por dia: ${this.history.alcoholism ? this.history.alcoholism_day : '----'}`, 1.5, table2Height + 3.0);
      doc.text(`Cuanto topa por semana: ${this.history.alcoholism ? this.history.alcoholism_week : '----'}`, 9.5, table2Height + 3.0);
      const heightPersonalSpace = table2Height + 3.0;
      doc.text(`Fuma: ${this.history.smoking ? 'Si' : 'No'}`, 1.5, heightPersonalSpace + 1);
      doc.text(`Veces que fuma: ${this.history.smoking ? this.history.smoking_observations : '----'}`, 1.5, heightPersonalSpace + 1.5);
      doc.text(`Años fumando: ${this.history.smoking ? this.history.tobacco_years : '----'}`, 5, heightPersonalSpace + 1.5);
      doc.text(`Cantidad de cigarros al dia: ${this.history.smoking ? this.history.tobacco_day : '----'}`, 9, heightPersonalSpace + 1.5);
      doc.text(`Indice tabaquico: ${this.history.smoking ? this.totalSmoke : '----'}`, 15, heightPersonalSpace + 1.5);
      const heightPersonalSpaceDrugs = heightPersonalSpace + 1.5;
      doc.text(`Drogas: ${this.history.drug_intake ? 'Si' : 'No'}`, 1.5, heightPersonalSpaceDrugs + 1);
      doc.text(`Veces que consume drogas: ${this.history.drug_intake_observations ? this.history.drug_intake_observations : '----'}`, 1.5, heightPersonalSpaceDrugs + 1.5);
      const heightPersonalSpaceExercise = heightPersonalSpaceDrugs + 1.5;
      doc.text(`Ejercicio: ${this.history.exercise ? 'Si' : 'No'}`, 1.5, heightPersonalSpaceExercise + 1);
      doc.text(`Veces que consume drogas: ${this.history.exercise ? this.history.exercise_observations : '----'}`, 1.5, heightPersonalSpaceExercise + 1.5);
      doc.addPage();
      doc.setFontSize(13);
      doc.text("Antecedentes Ginecológicos:", 7, 4.5);
      doc.setFontSize(10);
      doc.text(`Menarca: ${this.history.menarche}`, 1.5, 5.5);
      doc.text(`Gestaciones: ${this.history.gestations ? 'Si' : 'No'}`, 1.5, 6.5);
      doc.text(`Fecha de ultima gestación: ${this.history.gestations ? this.dateStringMxFormat(this.history.date_gestations) : '---'}`, 7.5, 6.5);
      doc.text(`Partos: ${this.history.gestations ? this.history.childbirth : '---'}`, 1.5, 7);
      doc.text(`Cesáreas: ${this.history.gestations ? this.history.caesarean : '---'}`, 4.5, 7);
      doc.text(`Abortos: ${this.history.gestations ? this.history.abortions : '---'}`, 7.5, 7);
      doc.text(`Óbitos: ${this.history.gestations ? this.history.deaths : '---'}`, 10.5, 7);
      doc.text(`Ectópicos: ${this.history.gestations ? this.history.ectopic : '---'}`, 13.5, 7);
      doc.text(`Comentarios:`, 1.5, 8);

      const strArrBackGestations = doc.splitTextToSize(this.history.comments_menarche, 18)
      const sumOtherGestations = 8.5;
      doc.text(strArrBackGestations, 1.5, sumOtherGestations);
      const firstParaHeight2 = doc.getTextDimensions(strArrBackGestations, {fontSize: doc.internal.getFontSize()}).h;
      doc.text(strArrBackGestations, 1.5, sumOtherGestations);
      const sumGestations = sumOtherGestations + firstParaHeight2

      doc.text(`Papanicolau: ${this.history.papanicolau ? 'Si' : 'No'}`, 1.5, sumGestations + 2);
      doc.text(`Fecha de ultimo papanicolau: ${this.history.papanicolau ? this.dateStringMxFormat(this.history.date_papanicolau) : '---'}`, 7.5, sumGestations + 2);
      doc.text(`Comentarios:`, 1.5, sumGestations + 3);

      const strArrPapanicolau = doc.splitTextToSize(this.history.comments_papanicolau ? this.history.comments_papanicolau : '', 18)
      const sumOtherPapanicolau = sumGestations + 3.5;
      doc.text(strArrPapanicolau, 1.5, sumOtherPapanicolau);
      const firstParaHeight3 = doc.getTextDimensions(strArrPapanicolau, {fontSize: doc.internal.getFontSize()}).h;
      const sumPapanicolau = sumOtherPapanicolau + firstParaHeight3
      doc.text(`Mamografía: ${this.history.mammography ? 'Si' : 'No'}`, 1.5, sumPapanicolau + 2);
      doc.text(`Fecha de ultimo mamografía: ${this.history.mammography ? this.dateStringMxFormat(this.history.date_mammography) : '---'}`, 7.5, sumPapanicolau + 2);
      doc.text(`Comentarios:`, 1.5, sumPapanicolau + 3);
      const strArrMammography = doc.splitTextToSize(this.history.comments_mammography ? this.history.comments_mammography : '', 18)
      const sumOtherMammography = sumPapanicolau + 3.5;
      doc.text(strArrMammography ? strArrMammography : '---', 1.5, sumOtherMammography);
      const firstParaHeight4 = doc.getTextDimensions(strArrMammography, {fontSize: doc.internal.getFontSize()}).h;
      const sumMammography = sumOtherMammography + firstParaHeight4
      doc.text(`Continúa Menstruando: ${this.history.continue_menstruating ? 'Si' : 'No'}`, 1.5, sumMammography + 2);
      doc.text(`Desde cuando: ${this.history.date_rule_no ? this.dateStringMxFormat(this.history.date_rule_no) : '---'}`, 7.5, sumMammography + 2);
      doc.text(`Fecha de ultima Regla: ${this.history.continue_menstruating ? this.dateStringMxFormat(this.history.date_rule) : '---'}`, 12.5, sumMammography + 2);
      doc.text(`Duración (Días): ${this.history.continue_menstruating ? this.history.duration_menstruating : '---'}`, 1.5, sumMammography + 3);
      doc.text(`Frecuencia (Días): ${this.history.continue_menstruating ? this.history.frequency_menstruating : '---'}`, 5.5, sumMammography + 3);
      doc.text(`Ciclos mentruales irregulares: ${this.history.continue_menstruating ? this.history.irregular_menstrual ? 'Si' : 'No' : '---'}`, 9.5, sumMammography + 3);
      doc.text(`Anticonceptivos: ${this.history.continue_menstruating ? this.history.contraceptives ? 'Si' : 'No' : '---'}`, 15.5, sumMammography + 3);

      doc.text(`Comentarios Generales:`, 1.5, sumMammography + 5);
      const strArrAll = doc.splitTextToSize(this.history.comments ? this.history.comments : '', 18)
      const sumOtherAll = sumMammography + 5.5;
      doc.text(strArrAll, 1.5, sumOtherAll);
      const firstParaHeight5 = doc.getTextDimensions(strArrAll, {fontSize: doc.internal.getFontSize()}).h;
      const sumAll = sumOtherAll + firstParaHeight5
      doc.text(`Diagnostico :`, 1.5, sumAll + 3);
      const strArrAllDesc = doc.splitTextToSize(this.note_evolution ? this.note_evolution : '---', 12)
      doc.text(strArrAllDesc, 1.5, sumAll + 3.5);

      doc.addPage();
      doc.setFontSize(11);
      const addTextSection = (doc, title, content, x, y, maxWidth) => {
        doc.setFont("helvetica", "bold");
        doc.text(title, x, y);
        const splitText = doc.splitTextToSize(content, maxWidth);
        doc.setFont("helvetica", "normal");
        doc.text(splitText, x, y + .5);
        const height = doc.getTextDimensions(splitText, {fontSize: doc.internal.getFontSize()}).h;
        return y + .5 + height;
      };

      let currentY = 2.5;
      const startX = 1.5;
      const maxWidth = 18;
      const importantBackground = this.general_information.important_background || '';
      currentY = addTextSection(doc, "Antecedentes Importantes:", importantBackground, startX, currentY + .5, maxWidth);

      if (this.general_information.have_allergies) {
        const allergies = this.general_information.allergies || '';
        currentY = addTextSection(doc, "Alergias:", allergies, startX, currentY + 1, maxWidth);
      }

      const psychologicalTreatment = this.general_information.psychological_treatment || '';
      currentY = addTextSection(doc, "Tratamiento Psicológico/Psiquiátrico:", psychologicalTreatment, startX, currentY + 1, maxWidth);

      const referPatient = this.general_information.refers_patient || '';
      currentY = addTextSection(doc, "Quién Refiere al paciente:", referPatient, startX, currentY + 1, maxWidth);

      const reasonConsulting = this.general_information.reason || '';
      currentY = addTextSection(doc, "Motivo de la consulta (Según el paciente):", reasonConsulting, startX, currentY + 1, maxWidth);


      const evolutionActually = this.general_information.observations || '';
      currentY = addTextSection(doc, "Analisis Consulta Primera Vez:", evolutionActually, startX, currentY + 1, maxWidth);
      if (!this.general_information.have_allergies) {
        const evolutionAllergies = this.general_information.allergies || '';
        currentY = addTextSection(doc, "Alergias:", evolutionAllergies, startX, currentY + 1, maxWidth);
      }

      if (this.getUser.department_id === 13) {


        const addFooters = async (doc) => {
          const pageCount = doc.internal.getNumberOfPages();
          let responsePatient;
          if (this.getUser.address_id) {
            responsePatient = await this.findPatientOne(this.getUser.address_id);
          }
          let responseDataSignature = null
          if (this.getUser.id) {
            this.show = true
            responseDataSignature = await this.getFindFullName(this.getUser.id);
            this.show = false
          }

          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
            const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
            const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
            const footerPosition = doc.internal.pageSize.getHeight();
            doc.text(footerAddress, 1.5, footerPosition - 0.5);
            doc.text(footerText, 1.5, footerPosition - 1);
            doc.text(footerName, 1.5, footerPosition - 1.5);
            if (responseDataSignature && responseDataSignature.signatureFile) {
              const img2 = new Image();
              img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
              doc.addImage(img2, "png", 16, footerPosition - 4, 3, 3);
            }
          }
        };
        await addFooters(doc);
      }

      doc.save(`historial-clinico-${this.data_patient.name}.pdf`);
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },


}
</script>

<style scoped>

</style>