<template>
  <div>
    <b-overlay :show="show" no-wrap/>
    <b-modal
        hide-footer
        id="modal-prevent-closing-laboratories"
        ref="my-modal"
        :title="`Agregar laboratorio`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Nombre Laboratorio"
          >
            <b-form-input
                v-model="name"
                placeholder="Nombre Laboratorio"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Dirección del Laboratorio"
          >
            <b-form-input
                v-model="address"
                placeholder="Dirección del Laboratorio"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Teléfono del Laboratorio"
          >
            <b-form-input
                v-model="phone"
                placeholder="Teléfono del Laboratorio"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              v-if="!show"
              @click="createLaboratoriesSend"
              style="width: 100%"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Guardar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import vSelect from "vue-select";

export default {
  name: "ConsultationsPatient",
  components: {
    vSelect,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  props: {
    getAllLaboratoriesAfterCreate: Function,
    modalLaboratories: Function,
    showStatus: Boolean,
  },
  data() {
    return {
      name:'',
      address:'',
      phone:'',
      show:this.showStatus,
    };
  },

  methods: {
    ...mapActions("studies", [
      "createLaboratories",
    ]),
    async createLaboratoriesSend() {
      if (!this.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El nombre es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.address) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `La dirección es requerida`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.phone) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El Telefóno  es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.name,
        address: this.address,
        phone: this.phone,
      };
      const response = await this.createLaboratories(send);
      await this.$bvModal.hide('modal-prevent-closing-laboratories')
      if (!response.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.name = '';
        this.address = '';
        this.phone = '';
        await this.getAllLaboratoriesAfterCreate(send.name, send.address,response, send.phone);
        this.show = false;
      } else {
        this.show = false;
        await this.$bvModal.show('modal-prevent-closing-laboratories')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    resetModal() {
      this.name = '';
      this.address = '';
      this.phone = '';
    },

  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}
</style>
