<template>
  <div>
    <b-overlay :show="show" no-wrap/>
    <ModalLaboratory :modalLaboratories="modalLaboratories"
                     :getAllLaboratoriesAfterCreate="getAllLaboratoriesAfterCreate" :showStatus="false"/>
    <ModalHospital :modalLHospital="modalLaboratories" :getAllLHospitalsAfterCreate="getAllLHospitalsAfterCreate"
                   :showStatus="false"/>
    <b-row>
      <b-col cols="12" lg="3">
        <b-button
            @click="newConsultations"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Consulta
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item
                style="cursor: pointer"
                v-for="(a, idx) in consults"
                :key="idx"
                @click="viewConsultation(a.id)"
            >
              {{ resHoursOdooFormat(a.createdAt) }}(ver archivo) <br/>
              <span v-if="data_patient.ResPartnerAppointment">{{ data_patient.ResPartnerAppointment.name }}</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="12" lg="9" v-if="createConsultations">

        <b-row style="margin-bottom: 30px">
          <b-col cols="12" lg="4">

          </b-col>
          <b-col cols="12" lg="4">
            <h3 style="text-align: center; margin-top: 15px">Consulta</h3>
          </b-col>
          <b-col cols="12" lg="4">
            <div align="right">
              <b-button
                  @click="exportPDFSummary"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Resumen
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>

          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input
                  readonly=""
                  v-model="consul.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6">


          </b-col>
          <b-col cols="6" lg="3">

            <b-form-group>
              <h5 class="font-weight-bold">Dia de consulta</h5>
              <flat-pickr
                  v-model="consul.date_consultation"
                  class="form-control"
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%"/>
            <VoiceInput  label="Nota de Evolución" placeholder="Nota de Evolución" rows="2" id="textarea-default1-evo"
                         v-model="consul.note_evolution"/>
          </b-col>

          <app-collapse style="width: 100%">
            <app-collapse-item style="width: 100%" active title="Signos Vitales y Somatometría" is-visible>
              <b-row>
                <b-col cols="6" lg="3">
                  <b-form-group label="Altura(Mts)">
                    <b-form-input
                        v-model="consul.height"
                        placeholder="Altura(Mts)"
                        @keyup="calculateImc"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Peso">
                    <b-form-input
                        v-model="consul.weight"
                        @keyup="calculateImc"
                        placeholder="Peso"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="IMC">
                    <b-form-input
                        v-model="consul.imc"
                        @click="calculateImc"
                        placeholder="IMC"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Superficie Corporal">
                    <b-form-input
                        v-model="consul.body_surface"
                        @click="calculateBody"
                        placeholder="Superficie Corporal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Temperatura">
                    <b-form-input
                        v-model="consul.temperature"
                        placeholder="Temperatura"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" lg="3">
                  <b-form-group label="Presión arterial">
                    <b-form-input
                        v-model="consul.blood_pressure"
                        placeholder="Presión arterial"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Oxigenación">
                    <b-form-input
                        v-model="consul.oxygenation"
                        placeholder="Oxigenación"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Frecuencia Cardiaca">
                    <b-form-input
                        v-model="consul.frecuency_cardiac"
                        placeholder="Frecuencia Cardiaca"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group label="Frecuencia Respiratoria">
                    <b-form-input
                        v-model="consul.frecuency_respiratory"
                        placeholder="Frecuencia Respiratoria"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group label="Perimetro Abdominal (cm)">
                    <b-form-input
                        v-model="consul.abdominal_perimeter"
                        placeholder="Perimetro Abdominal (cm)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Exploracion Fisica">
              <b-row>
                <b-col cols="12" class="">
                  <b-row>
                    <b-col cols="12">
                      <VoiceInput label="Exploración Fisica" placeholder="Exploración Fisica" rows="2"
                                  id="textarea-default1-explo-phisy" v-model="consul.physical_exploration"/>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Resultado estudios">
              <b-row>
                <b-col cols="12" class="">
                  <b-row>
                    <b-col cols="12">
                      <VoiceInput label="Resultado estudios" placeholder="Resultado estudios" rows="2"
                                  id="textarea-default1-explo-studie" v-model="consul.result_studies"/>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Diagnostico">
              <b-row>
                <b-col cols="12" class="">
                  <b-row>
                    <b-col cols="12">
                      <table>
                        <tr>
                          <th>Diagnostico</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(a, idx) in consul.diagnostics">
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Diagnostico"
                                  v-model="a.product"
                                  @keydown="searchDiagnostics(idx, 'create')"
                              />
                              <div
                                  v-if="openSelectDiagnostics === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineDiagnostics(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear Linea
                                </p>
                                <div
                                    v-if="listDiagnostics.length > 0"
                                    v-for="(b, idx2) in listDiagnostics"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectDiagnosticsList(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-button
                                v-if="!a.new_line"
                                @click="addRowDiagnostics(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeDiagnostics(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Medicamentos">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <b-row>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFRecipeCreateDigital()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir Receta Digital
                      </b-button>
                    </b-col>
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFRecipeCreate()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir Receta
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <h3 style="text-align: center">Medicamentos</h3>
                      <table>
                        <tr>
                          <th>Producto</th>
                          <th>Indicaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(a, idx) in consul.recipes">
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Medicamento"
                                  v-model="a.product"
                                  @keydown="searchProducts(idx, 'create')"
                              />
                              <div
                                  v-if="openSelect === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineProduct(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear producto
                                </p>
                                <div
                                    v-if="listProducts.length > 0"
                                    v-for="(b, idx2) in listProducts"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectProductList(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Indicaciones"
                                  v-model="a.indications"
                                  @keydown="searchIndications(idx, 'create')"
                              />
                              <div
                                  v-if="openSelectIndications === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineIndications(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear
                                </p>
                                <div
                                    v-if="listIndications.length > 0"
                                    v-for="(b, idx3) in listIndications"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectIndicationList(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-button
                                v-if="!a.new_line"
                                @click="addRowMedicines(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeLineMedicines(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Estudios y laboratorio">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <b-row>
                    <b-col cols="4">
                      <b-button
                          @click="modalLaboratories(true)"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Crear Laboratorio
                      </b-button>
                    </b-col>
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFStudiesCreate()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir Hoja de estudios
                      </b-button>
                    </b-col>
                  </b-row>
                  <h3 style="text-align: center">Estudios y laboratorio</h3>
                  <b-col cols="12">
                    <b-form-checkbox
                        class="custom-control-dark"
                        name="check-button3"
                        @input="changeLab(!consul.order_lab.recommend_lab)"
                        switch
                    />
                  </b-col>
                  <b-row v-if="consul.order_lab.recommend_lab">
                    <b-col cols="12">
                      <b-form-group
                          label="Nombre laboratorio"
                          label-for="h-email"
                      >
                        <v-select
                            @input="change_laboratory(false)"
                            v-model="extra_select_laboratorie"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="laboratories"
                        />
                      </b-form-group>

                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Dirección laboratorio">
                        <b-form-input
                            disabled
                            v-model="consul.order_lab.address"
                            placeholder="Dirección laboratorio"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Telefóno laboratorio">
                        <b-form-input
                            disabled
                            v-model="consul.order_lab.phone"
                            placeholder="Telefóno laboratorio"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <VoiceInput label="Observaciones" placeholder="Observaciones" rows="2" id="textarea-default1-obse"
                                  v-model="consul.order_lab.observations"/>
                    </b-col>
                    <b-col cols="12">
                      <b-card-text class="mb-0"> Incluye Diagnostico</b-card-text>
                      <b-form-checkbox
                          :checked="consul.order_lab.include_diagnostic"
                          class="custom-control-dark"
                          name="check-button5"
                          @input="changeDiagnostic(!consul.order_lab.include_diagnostic)"
                          switch
                      />
                    </b-col>
                    <b-col cols="12">

                      <VoiceInput v-if="consul.order_lab.include_diagnostic"
                                  label="Diagnostico" placeholder="Diagnostico" rows="2"
                                  id="textarea-default1-obse-diag" v-model="consul.order_lab.diagnostic"/>

                    </b-col>
                    <b-col cols="12" class="mt-1">
                      <table>
                        <tr>
                          <th>Estudio</th>
                          <th>Indicaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(c, idx) in consul.order_lab.lines">
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Estudios"
                                  v-model="c.product"
                                  @keydown="searchStudies(idx, 'create')"
                              />
                              <div
                                  v-if="openSelectStudies === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineStudy(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear producto
                                </p>
                                <div
                                    v-if="listStudiesLab.length > 0"
                                    v-for="(b, idx2) in listStudiesLab"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectStudyLab(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-form-input
                                placeholder="Indicaciones"
                                v-model="c.indications"
                            />
                          </td>


                          <td>
                            <b-button
                                v-if="!c.new_line"
                                @click="addRowStudies(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"

                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeStudies(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                  <b-row v-else></b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Orden de Hospitalización">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h4>Orden de Hospitalización</h4>
                  <hr style="width: 100%"/>
                  <b-col cols="12">
                    <b-form-checkbox
                        class="custom-control-dark"
                        name="check-button3"
                        @input="changeHospital(!consul.is_hospitalization)"
                        switch
                    />
                  </b-col>
                  <div v-if="consul.is_hospitalization">
                    <b-row style="margin-top: 30px; margin-bottom: 40px">

                      <b-col cols="4">
                        <b-button
                            @click="modalLHospital(true)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar Hospital
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                      </b-col>
                      <b-col cols="4">
                        <div align="right">
                          <b-button
                              @click="exportPDFHospitalCreate()"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                          >
                            Imprimir orden
                          </b-button>
                        </div>

                      </b-col>
                      <b-col cols="12" style="margin-top: 20px">
                        <b-form-group
                            label="Nombre Hospital"
                            label-for="h-email"
                        >
                          <v-select
                              @input="change_hospital(false)"
                              v-model="hospitals"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              :options="listHospitals"
                          />
                        </b-form-group>

                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Dirección Hospital">
                          <b-form-input
                              disabled
                              v-model="consul.address_hospital"
                              placeholder="Dirección Hospital"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Teléfono hospital">
                          <b-form-input
                              disabled
                              v-model="consul.phone_hospital"
                              placeholder="Teléfono hospital"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Medico responsable">
                          <b-form-input
                              v-model="consul.medical_hospitalization"
                              placeholder="Medico responsable"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <h5 class="font-weight-bold">Fecha de ingreso</h5>
                          <flat-pickr
                              v-model="consul.date_hospitalization"
                              class="form-control"
                              :config="{
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <h1 style="font-size: 17px; margin-bottom: 20px; margin-top: 20px">Favor de hospitalizar a:
                      {{ data_patient.name }}</h1>
                    <VoiceInput label="Orden de Hospitalización" placeholder="Orden de Hospitalización" rows="2"
                                id="textarea-default1-hopsi" v-model="consul.hospitalization"/>
                  </div>


                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Tratamiento Oncologico">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                    </b-col>
                  </b-row>
                  <h3 style="text-align: center">Tratamiento Oncologico</h3>
                  <b-row>
                    <b-col cols="12" class="mt-1">
                      <table>
                        <tr>
                          <th>Tratamiento</th>
                          <th>Anotaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(c, idx) in consul.oncologicalTretaments.oncologicalLines">
                          <td>
                            <b-form-group label="Tratamiento" label-for="h-email">
                              <v-select
                                  v-model="c.treatment"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  label="value"
                                  :options="linesTreatmentsOncological"
                              />
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-input
                                placeholder="Anotaciones"
                                v-model="c.description"
                            />
                          </td>
                          <td>
                            <b-button
                                v-if="!c.new_line"
                                @click="addRowOncological(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeOncological(idx,'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col cols="12" style="margin-top: 40px">
                      <b-card-text class="mb-0"> Diagnóstico molecular</b-card-text>
                      <b-form-checkbox
                          :checked="consul.oncologicalTretaments.molecular_diagnosis"
                          class="custom-control-dark"
                          name="check-button5"
                          @input="changeDiagnosticOncological(!consul.oncologicalTretaments.molecular_diagnosis, 'create')"
                          switch
                      />

                      <VoiceInput style="margin-top: 40px"
                                  label="Anotaciones" placeholder="Anotaciones" rows="2" id="textarea-default1-anot"
                                  v-model="consul.oncologicalTretaments.description"/>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Tratamiento" label-for="h-email">
                        <v-select
                            v-model="lineTreatment"
                            @input="changeTreatmentReason('create')"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="linesTreatments"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">

                      <b-form-group label="Respues de tratamiento" label-for="h-email">
                        <v-select
                            v-model="answerTreatment"
                            @input="changeAnswerReason('create')"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="answerTreatments"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">

                      <VoiceInput style="margin-top: 40px"
                                  label="Especificaciones" placeholder="Anotaciones" rows="2"
                                  id="textarea-default1-onco-spec"
                                  v-model="consul.oncologicalTretaments.specifications"/>
                    </b-col>

                  </b-row>

                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Analisis">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Analisis</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Analisis" placeholder="Analisis" rows="2" id="textarea-default1-plan"
                                  v-model="consul.analysis_and_plan"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Plan Terapéutico">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Plan Terapéutico</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Plan Terapéutico" placeholder="Plan Terapéutico" rows="2"
                                  id="textarea-default1-plan-there"
                                  v-model="consul.therapeutic_plan"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Indicaciones Extras">
              <b-row>
                <b-col cols="12" class="mt-3 card-recipe">
                  <h3 style="text-align: center">Indicaciones Extras</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Indicaciones Extras" placeholder="Indicaciones Extras" rows="2"
                                  id="textarea-default1-extra"
                                  v-model="consul.extra_indications"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Archivos">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Archivos</h3>
                  <b-row class="mt-3">
                    <b-col cols="9">
                      <b-row class="mt-3">
                        <b-col
                            cols="12"
                            v-for="(a, idx) in ClinicConsultationsFiles"
                            :key="idx"
                        >
                          <b-form-group label="Subir Archivo" label-for="h-email">
                            <b-form-file
                                @change="handleImage(idx)"
                                :id="`file${idx}`"
                                placeholder="Subir archivo."
                                accept="application/pdf"
                                drop-placeholder="Drop file here..."
                            />
                          </b-form-group>
                          <div align="right">
                            <b-button
                                @click="quitFile(idx)"
                                style="width: 25%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Quitar archivo
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="3">
                      <b-button
                          @click="addInputFile"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Nuevo archivo
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>


          <b-col cols="12" class="mt-3">
            <b-button
                @click="createConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" lg="9" v-if="updateInformation">
        <b-row style="margin-bottom: 30px">
          <b-col cols="12" lg="4">

          </b-col>
          <b-col cols="12" lg="4">
            <h3 style="text-align: center; margin-top: 15px">Consulta</h3>
          </b-col>
          <b-col cols="12" lg="4">
            <div align="right">
              <b-button
                  @click="exportPDFSummaryUpdate"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Resumen
              </b-button>
            </div>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group label="Edad">
              <b-form-input
                  readonly=""
                  v-model="consultations.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6">
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">Dia de consulta</h5>
              <flat-pickr
                  v-model="consultations.date_consultation"
                  class="form-control"
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%"/>
            <VoiceInput label="Nota de Evolución" placeholder="Nota de Evolución" rows="2"
                        id="textarea-default1-note-edit"
                        v-model="consultations.note_evolution"/>
          </b-col>
          <app-collapse style="width: 100%">
            <app-collapse-item style="width: 100%" active title="Signos Vitales y Somatometría" is-visible>
              <b-row>
                <b-col cols="6" lg="3">
                  <b-form-group label="Altura(Mts)">
                    <b-form-input
                        v-model="consultations.height"
                        @keyup="calculateImcEdit"
                        placeholder="Altura(Mts)"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Peso">
                    <b-form-input
                        v-model="consultations.weight"
                        @keyup="calculateImcEdit"
                        placeholder="Peso"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="IMC">
                    <b-form-input
                        v-model="consultations.imc"
                        @click="calculateImcEdit"
                        placeholder="IMC"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Superficie Corporal">
                    <b-form-input
                        v-model="consultations.body_surface"
                        @click="calculateImcEdit"
                        placeholder="Superficie Corporal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Temperatura">
                    <b-form-input
                        v-model="consultations.temperature"
                        placeholder="Temperatura"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" lg="3">
                  <b-form-group label="Presión arterial">
                    <b-form-input
                        v-model="consultations.blood_pressure"
                        placeholder="Presión arterial"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Oxigenación">
                    <b-form-input
                        v-model="consultations.oxygenation"
                        placeholder="Oxigenación"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Frecuencia Cardiaca">
                    <b-form-input
                        v-model="consultations.frecuency_cardiac"
                        placeholder="Frecuencia Cardiaca"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group label="Frecuencia Respiratoria">
                    <b-form-input
                        v-model="consultations.frecuency_respiratory"
                        placeholder="Frecuencia Respiratoria"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group label="Perimetro Abdominal (cm)">
                    <b-form-input
                        v-model="consultations.abdominal_perimeter"
                        placeholder="Perimetro Abdominal (cm)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Exploracion Fisica">
              <b-row>
                <b-col cols="12" class="mt-3 ">
                  <b-row>
                    <b-col cols="12">
                      <h4>Exploracion Fisica</h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Exploracion Fisica" placeholder="Exploracion Fisica" rows="2"
                                  id="textarea-default1-note-edit-exp"
                                  v-model="consultations.physical_exploration"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Resultado estudios">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h4>Resultado estudios</h4>
                  <hr style="width: 100%"/>
                  <VoiceInput label="Resultado estudios" placeholder="Resultado estudios" rows="2"
                              id="textarea-default1-note-edit-studires"
                              v-model="consultations.result_studies"/>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Diagnostico">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h4>Diagnostico</h4>
                  <hr style="width: 100%"/>
                  <table>
                    <tr>
                      <th>Diagnostico</th>
                      <th>Acciones</th>
                    </tr>
                    <tr v-for="(a, idx) in consultations.ClinicDiagnostics">
                      <td>
                        <div style="position: relative">
                          <b-form-input
                              placeholder="Diagnostico"
                              v-model="a.product"
                              @keydown="searchDiagnostics(idx, 'edit')"
                          />
                          <div
                              v-if="openSelectDiagnostics === idx"
                              style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                          >
                            <p
                                @click="createLineDiagnosticsEdit(idx)"
                                style="cursor: pointer; user-select: none"
                            >
                              Crear Linea
                            </p>
                            <div
                                v-if="listDiagnostics.length > 0"
                                v-for="(b, idx2) in listDiagnostics"
                            >
                              <p
                                  style="cursor: pointer; user-select: none"
                                  @click="selectDiagnosticsListEdit(idx, b.id, b.name)"
                              >
                                {{ b.name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b-button
                            v-if="!a.new_line"
                            @click="addRowDiagnostics(idx, 'edit')"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar
                        </b-button>
                        <b-button
                            v-else
                            @click="removeDiagnostics(idx, 'edit')"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Borrar
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Medicamentos">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <b-row>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFRecipeDigital()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir Receta Digital
                      </b-button>
                    </b-col>
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFRecipe()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir receta
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <h3 style="text-align: center">Medicamentos</h3>
                      <table>
                        <tr>
                          <th>Producto</th>
                          <th>Indicaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(a, idx) in consultations.ClinicRecipes">
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Medicamento"
                                  v-model="a.product"
                                  @keydown="searchProducts(idx, 'edit')"
                              />
                              <div
                                  v-if="openSelect === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                          "
                              >
                                <p
                                    @click="createLineProductEdit(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear producto
                                </p>
                                <div
                                    v-if="listProducts.length > 0"
                                    v-for="(b, idx2) in listProducts"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectProductListEdit(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Indicaciones"
                                  v-model="a.indications"
                                  @keydown="searchIndications(idx, 'edit')"
                              />
                              <div
                                  v-if="openSelectIndications === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineIndicationsEdit(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear
                                </p>
                                <div
                                    v-if="listIndications.length > 0"
                                    v-for="(b, idx3) in listIndications"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectIndicationListEdit(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-button
                                v-if="!a.new_line"
                                @click="addRowMedicines(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeLineMedicines(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Estudios y laboratorio">
              <b-row>
                <b-col
                    v-if="consultations.ClinicOrderStudy"
                    cols="12"
                    class="mt-3 "

                >
                  <b-row>
                    <b-col cols="4">
                      <b-button
                          @click="modalLaboratories(true)"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Crear Laboratorio
                      </b-button>
                    </b-col>
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                      <b-button
                          @click="exportPDFStudies()"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Imprimir Hoja de estudios
                      </b-button>
                    </b-col>
                  </b-row>
                  <h3 style="text-align: center">Estudios y laboratorio</h3>
                  <b-col cols="12">
                    <b-form-checkbox
                        :checked="consultations.ClinicOrderStudy.recommend_lab"
                        class="custom-control-dark"
                        @input="
                  changeLabUpdate(!consultations.ClinicOrderStudy.recommend_lab)
                "
                        switch
                    />
                  </b-col>

                  <b-row v-if="consultations.ClinicOrderStudy.recommend_lab">
                    <b-col cols="12">
                      <b-form-group
                          label="Nombre laboratorio"
                          label-for="h-email"
                      >
                        <v-select
                            @input="change_laboratory(true)"
                            v-model="extra_select_laboratorie"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="laboratories"
                        />
                      </b-form-group>

                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Dirección laboratorio">
                        <b-form-input
                            disabled
                            v-model="consultations.ClinicOrderStudy.address"
                            placeholder="Dirección laboratorio"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Telefóno laboratorio">
                        <b-form-input
                            disabled
                            v-model="consultations.ClinicOrderStudy.phone"
                            placeholder="Telefóno laboratorio"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <VoiceInput label="Observaciones" placeholder="Observaciones" rows="2"
                                  id="textarea-default1-obse-edit"
                                  v-model="consultations.ClinicOrderStudy.observations"/>
                    </b-col>
                    <b-col cols="12">
                      <b-card-text class="mb-0"> Incluye Diagnostico</b-card-text>
                      <b-form-checkbox
                          :checked="consultations.ClinicOrderStudy.include_diagnostic"
                          class="custom-control-dark"
                          name="check-button"
                          @input="
                    changeDiagnosticUpdate(
                      !consultations.ClinicOrderStudy.include_diagnostic
                    )
                  "
                          switch
                      />

                      <VoiceInput v-if="consultations.ClinicOrderStudy.include_diagnostic"
                                  label="Diagnostico" placeholder="Diagnostico" rows="2"
                                  id="textarea-default1-obse-edit-diag"
                                  v-model="consultations.ClinicOrderStudy.diagnostic"/>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                      <table>
                        <tr>
                          <th>Estudio</th>
                          <th>Indicaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr
                            v-for="(b, idx) in consultations.ClinicOrderStudy
                      .ClinicStudiesLines"
                        >
                          <td>
                            <div style="position: relative">
                              <b-form-input
                                  placeholder="Estudios"
                                  v-model="b.product"
                                  @keydown="searchStudies(idx, 'edit')"
                              />
                              <div
                                  v-if="openSelectStudies === idx"
                                  style="
                            width: 100%;
                            background: white;
                            padding: 10px;
                            border: 1px black solid;
                            position: absolute;
                            z-index: 100;
                            overflow-y: scroll;
                            height: 120px;
                          "
                              >
                                <p
                                    @click="createLineStudyEdit(idx)"
                                    style="cursor: pointer; user-select: none"
                                >
                                  Crear producto
                                </p>
                                <div
                                    v-if="listStudiesLab.length > 0"
                                    v-for="(b, idx2) in listStudiesLab"
                                >
                                  <p
                                      style="cursor: pointer; user-select: none"
                                      @click="selectStudyLabEdit(idx, b.id, b.name)"
                                  >
                                    {{ b.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-form-input
                                placeholder="Indicaciones"
                                v-model="b.indications"
                            />
                          </td>
                          <td>
                            <b-button
                                v-if="!b.new_line"
                                @click="addRowStudies(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeStudies(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Orden de Hospitalización">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h4>Orden de Hospitalización</h4>
                  <hr style="width: 100%"/>

                  <b-col cols="12">
                    <b-form-checkbox
                        :checked="consultations.is_hospitalization"
                        class="custom-control-dark"
                        name="check-button3"
                        @input="changeHospitalUpdate(!consultations.is_hospitalization)"
                        switch
                    />
                  </b-col>
                  <div v-if="consultations.is_hospitalization">
                    <b-row style="margin-top: 30px; margin-bottom: 40px">

                      <b-col cols="4">
                        <b-button
                            @click="modalLHospital(true)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar Hospital
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                      </b-col>
                      <b-col cols="4">
                        <div align="right">
                          <b-button
                              @click="exportPDFHospitalUpdate()"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                          >
                            Imprimir orden
                          </b-button>
                        </div>

                      </b-col>
                      <b-col cols="12" style="margin-top: 20px">
                        <b-form-group
                            label="Nombre Hospital"
                            label-for="h-email"
                        >
                          <v-select
                              @input="change_hospital(true)"
                              v-model="hospitals"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              :options="listHospitals"
                          />
                        </b-form-group>

                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Dirección Hospital">
                          <b-form-input
                              disabled
                              v-model="consultations.address_hospital"
                              placeholder="Dirección Hospital"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Teléfono hospital">
                          <b-form-input
                              disabled
                              v-model="consultations.phone_hospital"
                              placeholder="Teléfono hospital"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Medico responsable">
                          <b-form-input
                              v-model="consultations.medical_hospitalization"
                              placeholder="Medico responsable"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <h5 class="font-weight-bold">Dia de consulta</h5>
                          <flat-pickr
                              v-model="consultations.date_hospitalization"
                              class="form-control"
                              :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d',
                  locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado',
                      ],
                    },
                    months: {
                      shorthand: [
                        'Ene',
                        'Feb',
                        'Mar',
                        'Abr',
                        'May',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Sep',
                        'Оct',
                        'Nov',
                        'Dic',
                      ],
                      longhand: [
                        'Enero',
                        'Febrero',
                        'Мarzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    },
                  },
                }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <h1 style="font-size: 17px; margin-bottom: 20px; margin-top: 20px">Favor de hospitalizar a:
                      {{ data_patient.name }}</h1>
                    <VoiceInput label="Orden de Hospitalización" placeholder="Orden de Hospitalización" rows="2"
                                id="textarea-default1-hospi-edit"
                                v-model="consultations.hospitalization"/>
                  </div>


                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Tratamiento Oncologico">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                    </b-col>
                  </b-row>
                  <h3 style="text-align: center">Tratamiento Oncologico</h3>
                  <b-row>
                    <b-col cols="12" class="mt-1">
                      <table>
                        <tr>
                          <th>Tratamiento</th>
                          <th>Anotaciones</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(c, idx) in consultations.ClinicOncologicalTreatment.ClinicOncologicalLines">
                          <td>
                            <b-form-group label="Tratamiento" label-for="h-email">
                              <v-select
                                  v-model="c.treatment"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  label="value"
                                  :options="linesTreatmentsOncological"
                              />
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-input
                                placeholder="Anotaciones"
                                v-model="c.description"
                            />
                          </td>
                          <td>
                            <b-button
                                v-if="!c.new_line"
                                @click="addRowOncological(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Agregar
                            </b-button>
                            <b-button
                                v-else
                                @click="removeOncological(idx,'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Borrar
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col cols="12" style="margin-top: 40px">
                      <b-card-text class="mb-0"> Diagnóstico molecular</b-card-text>
                      <b-form-checkbox
                          :checked="consultations.ClinicOncologicalTreatment.molecular_diagnosis"
                          class="custom-control-dark"
                          name="check-button5"
                          @input="changeDiagnosticOncological(!consultations.ClinicOncologicalTreatment.molecular_diagnosis, 'edit')"
                          switch
                      />

                      <VoiceInput style="margin-top: 40px"
                                  label="Anotaciones" placeholder="Anotaciones" rows="2"
                                  id="textarea-default1-anot-edit"
                                  v-model="consultations.ClinicOncologicalTreatment.description"/>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Tratamiento" label-for="h-email">
                        <v-select
                            v-model="lineTreatment"
                            @input="changeTreatmentReason('edit')"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="linesTreatments"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">

                      <b-form-group label="Respues de tratamiento" label-for="h-email">
                        <v-select
                            v-model="answerTreatment"
                            @input="changeAnswerReason('edit')"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="value"
                            :options="answerTreatments"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">

                      <VoiceInput style="margin-top: 40px"
                                  label="Especificaciones" placeholder="Especificaciones" rows="2"
                                  id="textarea-default1-spec-edit"
                                  v-model="consultations.ClinicOncologicalTreatment.specifications"/>
                    </b-col>

                  </b-row>

                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Analisis">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Analisis</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Analisis" placeholder="Analisis" rows="2" id="textarea-default1-plan"
                                  v-model="consultations.analysis_and_plan"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Plan Terapéutico">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Plan Terapéutico</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Plan Terapéutico" placeholder="Plan Terapéutico" rows="2" id="textarea-default1-plan-edit"
                                  v-model="consultations.therapeutic_plan"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Indicaciones Extras">
              <b-row>
                <b-col cols="12" class="mt-3 card-recipe">
                  <h3 style="text-align: center">Indicaciones Extras</h3>
                  <b-row>
                    <b-col cols="12">
                      <h4></h4>
                      <hr style="width: 100%"/>
                      <VoiceInput label="Indicaciones Extras" placeholder="Indicaciones Extras" rows="2" id="textarea-default1-extra-edit"
                                  v-model="consultations.extra_indications"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Archivos">
              <b-row>
                <b-col cols="12" class="mt-3">
                  <h3 style="text-align: center">Archivos</h3>
                  <b-row class="mt-3">
                    <b-col cols="9">
                      <b-row class="mt-3">
                        <b-col
                            cols="12"
                            style="margin-top: 10px"
                            v-for="(c, idx) in ClinicConsultationsFilesEdit"
                            :key="idx"
                        >
                          <b-form-group label="Subir Archivo" label-for="h-email">
                            <b-form-file
                                @change="handleImageEdit(idx)"
                                :id="`fileEdit${idx}`"
                                placeholder="Subir archivo."
                                accept="application/pdf"
                                drop-placeholder="Drop file here..."
                            />
                          </b-form-group>
                          <div align="right">
                            <b-button
                                @click="quitFileEdit(idx)"
                                style="width: 25%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                            >
                              Quitar archivo
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="3">
                      <b-button
                          @click="editInputFile"
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Nuevo archivo
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        v-if="FilesDownloadList"
                        v-for="(b, idx) in FilesDownloadList"
                        :key="idx"
                        cols="4"
                        class="mt-1"
                    >
                      <a
                          @click="deleteFileConsultant(b.id)"
                          style="text-align: right"
                      >
                        <feather-icon
                            icon="TrashIcon"
                            style="color: darkred"
                            size="20"
                        />
                      </a>
                      <a @click="downloadFileOne(b.id)" style="text-align: center">
                        <feather-icon icon="FolderIcon" size="100"/>
                        <br/>
                        <span style="text-align: center">{{ b.name }} </span>
                        <br/>
                        <feather-icon
                            icon="DownloadIcon"
                            style="color: black"
                            size="20"
                        />
                        Descargar Archivo
                      </a>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>


          <b-col cols="12" class="mt-3">
            <b-button
                @click="updateConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import ModalLaboratory from "../../components/Patients/Consultations/ModalLaboratory.vue";
import ModalHospital from "../../components/Patients/Consultations/ModalHospital.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import logo from "../../assets/images/logo/logo-1.png";
import signature from "../../assets/images/logo/signature.png";
import {dateFolio, dateStringMonth, dateTodayFormat, formatHour, getFormat, sumMonthsHours} from "@/utils/date";
import vSelect from "vue-select";
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "ConsultationsPatient",
  components: {
    VoiceInput,
    vSelect,
    ModalLaboratory,
    ModalHospital,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  props: {
    title: String,
    data_patient: Object,
  },
  data() {
    return {
      lineTreatment: {id: "", value: "Seleccione un tratamiento"},
      answerTreatment: {id: "", value: "Seleccione un tipo de respuesta"},
      linesOncological: {id: "", value: "Seleccione un tratamiento"},
      hospitals: {id: "", value: "Seleccione un tratamiento"},
      openSelect: null,
      extra_select_laboratorie: {id: "", value: "Seleccione un laboratorio"},
      laboratories: [],
      linesTreatments: [],
      listHospitals: [],
      linesTreatmentsOncological: [],
      answerTreatments: [],
      laboratoriesAll: [],
      listIndications: [],
      openSelectDiagnostics: null,
      openSelectStudies: null,
      show: false,
      openSelectIndications: null,
      listProducts: [],
      listStudiesLab: [],
      ClinicConsultationsFiles: [
        {
          file: null,
          name: null,
          position: null,
        },
      ],

      consultations: {
        id: null,
        height: null,
        weight: null,
        abdominal_perimeter: null,
        medical_hospitalization: null,
        date_hospitalization: null,
        name_hospital: null,
        address_hospital: null,
        phone_hospital: null,
        hospital_list_id: null,
        blood_pressure: null,
        temperature: null,
        body_surface: null,
        heart_rate: null,
        diagnosis: null,
        frecuency_respiratory: null,
        is_hospitalization: false,
        frecuency_cardiac: null,
        note_evolution: null,
        years: null,
        imc: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        analysis_and_plan: null,
        therapeutic_plan: null,
        patient_id: null,
        hospitalization: null,
        appointee_id: null,
        date_consultation: null,
        ClinicRecipes: [],
        ClinicDiagnostics: [],
        ClinicOncologicalTreatment: {},
        ClinicConsultationsFiles: [],
        ClinicOrderStudy: {},
      },
      consults: [],
      ClinicConsultationsFilesEdit: [],
      FilesDownloadList: [],
      patient_id: null,
      totalCountFiles: null,
      createConsultations: false,
      listDiagnostics: [],
      updateInformation: false,
      consul: {
        height: null,
        abdominal_perimeter: null,
        hospital_list_id: null,
        diagnosis: null,
        weight: null,
        analysis_and_plan: null,
        years: this.data_patient.birthday
            ? this.yearsCalculate(this.data_patient.birthday)
            : null,
        body_surface: null,
        date_consultation: moment().format("YYYY-MM-DD"),
        date_consultation_hours: dateTodayFormat(),
        blood_pressure: null,
        temperature: null,
        heart_rate: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        imc: null,
        is_hospitalization: false,
        note_evolution: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        therapeutic_plan: null,
        patient_id: null,
        appointee_id: null,
        study_field: null,
        study_school: null,
        visa_no: null,
        permit_no: null,
        name_doctor: null,
        folio: null,
        date_validity: null,
        hospitalization: null,
        medical_hospitalization: null,
        date_hospitalization: moment().format("YYYY-MM-DD"),
        name_hospital: null,
        address_hospital: null,
        phone_hospital: null,
        img_qr: null,
        recipes: [
          {
            recipe_medicines_id: null,
            product: null,
            indications: null,
            new_line: null,
          },
        ],
        ClinicConsultationsFiles: [],
        oncologicalTretaments: {
          description: null,
          line_treatment: null,
          answer_treatment: null,
          specifications: null,
          molecular_diagnosis: false,
          oncologicalLines: [
            {
              treatment: null,
              description: null,
              new_line: false,
            }
          ]
        },
        diagnostics: [
          {
            diagnostics_list_id: null,
            product: null,
            new_line: null,
          },
        ],
        order_lab: {
          name: null,
          address: null,
          phone: null,
          observations: null,
          list_laboratories_id: null,
          recommend_lab: false,
          include_diagnostic: false,
          diagnostic: null,
          lines: [
            {
              study_lab_id: null,
              product: null,
              indications: null,
              new_line: false,
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.$root.$on("chargeConsultation", async () => {
      // your code goes here
      this.patient_id = this.data_patient.id;
      this.consul.study_school = this.getUser.study_school;
      this.consul.visa_no = this.getUser.visa_no;
      this.consul.permit_no = this.getUser.permit_no;
      this.consul.vat =  this.getUser.identification_id ? this.getUser.identification_id : "";
      this.consul.name_doctor = this.getUser.name;
      this.consul.folio = this.getUser.folio;
      this.consul.study_field = this.getUser.study_field;
      this.consul.img_qr = this.getUser.img_qr;
      await this.getConsultations();
    });
  },
  methods: {
    ...mapActions("patient", [
      "findPatientAllConsultations",
      "findPatientOne",
      "createConsultation",
      "findPatientOneConsultations",
      "updateConsultationStore",
      "findPatientAllConsultationsDesc",
      "deleteFileRequest",
      "downloadFiConsultations",
      "findMedicinesSearch",
      "createProductMedicines",
      "generateQrRecipe",
      "findListAnswerTreatments",
      "findListLineTreatments",
      "findListTreatmentsLines",
      "generateQrStudies",
      "findPatientOneInformation",
      "findIndicationsSearch",
      "createProductIndications",
      "getFindFullName"
    ]),
    ...mapActions("studies", [
      "findStudiesLab",
      "createProductStudiesLab",
      "findDiagnostics",
      "createProductDiagnostics",
      "findLaboratoriesAll",
      "createLaboratories",
    ]),
    ...mapActions("hospitals", [
      "createHospitalsLab",
      "findHospitalsAll",
      "findHospitalsLab",
    ]),
    async modalLaboratories(open) {
      if (open) {
        await this.$bvModal.show('modal-prevent-closing-laboratories')
      } else {
        await this.$bvModal.hide('modal-prevent-closing-laboratories')
      }
    },
    async modalLHospital(open) {
      if (open) {
        await this.$bvModal.show('modal-prevent-closing-hospital')
      } else {
        await this.$bvModal.hide('modal-prevent-closing-hospital')
      }
    },

    async listHospitalGets() {
      const response = await this.findHospitalsAll();
      this.listHospitals = []
      for (const a of response) {
        this.listHospitals.push({
          id: a.id,
          value: a.name,
          phone: a.phone,
          address: a.address,
        })
      }
    },

    async listAnswerTreatments() {
      const response = await this.findListAnswerTreatments();
      this.answerTreatments = []
      for (const a of response) {
        this.answerTreatments.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async listAllTreatments() {
      const response = await this.findListTreatmentsLines();
      this.linesTreatmentsOncological = []
      for (const a of response) {
        this.linesTreatmentsOncological.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async listLineTreatments() {
      const response = await this.findListLineTreatments();
      this.linesTreatments = []
      for (const a of response) {
        this.linesTreatments.push({
          id: a.value,
          value: a.value,
        })
      }
    },
    async changeTreatmentReason(type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.line_treatment = this.lineTreatment.id;
      } else {
        this.consultations.ClinicOncologicalTreatment.line_treatment = this.lineTreatment.id;
      }

    },

    async changeAnswerReason(type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.answer_treatment = this.answerTreatment.id;
      } else {
        this.consultations.ClinicOncologicalTreatment.answer_treatment = this.answerTreatment.id;
      }
    },
    change_hospital(edit) {
      if (!edit) {

        this.consul.hospital_list_id = this.hospitals.id;

        const findHospital = this.listHospitals.find((a) => a.id === this.hospitals.id);
        if (!findHospital) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este hospital',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }

        this.consul.name_hospital = findHospital.value;
        this.consul.phone_hospital = findHospital.phone;
        this.consul.address_hospital = findHospital.address;

      } else {
        this.consultations.hospital_list_id = this.hospitals.id;
        const findHospital = this.listHospitals.find((a) => a.id === this.hospitals.id);
        if (!findHospital) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este hospital',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.consultations.name_hospital = findHospital.value;
        this.consultations.phone_hospital = findHospital.phone;
        this.consultations.address_hospital = findHospital.address;
      }

    },
    change_laboratory(edit) {
      if (!edit) {
        this.consul.order_lab.list_laboratories_id = this.extra_select_laboratorie.id;

        const findLaboratory = this.laboratoriesAll.find((a) => a.id === this.extra_select_laboratorie.id);
        if (!findLaboratory) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este laboratorio',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }

        this.consul.order_lab.name = findLaboratory.name;
        this.consul.order_lab.phone = findLaboratory.phone;
        this.consul.order_lab.address = findLaboratory.address;

      } else {
        this.consultations.ClinicOrderStudy.list_laboratories_id = this.extra_select_laboratorie.id;
        const findLaboratory = this.laboratoriesAll.find((a) => a.id === this.extra_select_laboratorie.id);
        if (!findLaboratory) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.consultations.ClinicOrderStudy.name = findLaboratory.name;
        this.consultations.ClinicOrderStudy.phone = findLaboratory.phone;
        this.consultations.ClinicOrderStudy.address = findLaboratory.address;
      }

    },
    async createLineIndicationsEdit(idx) {
      if (!this.consultations.ClinicRecipes[idx].indications) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicRecipes[idx].indications,
      };
      const response = await this.createProductIndications(send);
      if (!response.error) {
        this.show = false;
        this.openSelectIndications = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineIndications(idx) {
      if (!this.consul.recipes[idx].indications) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.recipes[idx].indications,
      };
      const response = await this.createProductIndications(send);
      if (!response.error) {
        this.show = false;
        this.openSelectIndications = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async selectIndicationList(idx, id, name) {
      this.consul.recipes[idx].indications = name;
      this.openSelectIndications = null;
    },
    async selectIndicationListEdit(idx, id, name) {
      this.consultations.ClinicRecipes[idx].indications = name;
      this.openSelectIndications = null;
    },
    async getAllLaboratories() {
      this.show = true;
      const response = await this.findLaboratoriesAll();

      this.laboratories = [];
      this.laboratoriesAll = [];
      for (const a of response.laboratories) {
        this.laboratories.push({
          id: a.id,
          value: a.name,
        })
      }
      this.laboratoriesAll = response.laboratories;
      this.show = false;

    },
    async getAllLHospitalsAfterCreate(name, address, id, phone) {
      this.show = true;
      const response = await this.findHospitalsAll();

      this.listHospitals = [];
      for (const a of response) {
        this.listHospitals.push({
          id: a.id,
          value: a.name,
          phone: a.phone,
          address: a.address,
        })
      }


      const findHospital = this.listHospitals.find((a) => a.id === id);
      if (!findHospital) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No se encontro informacion sobre este hospital',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.hospitals.id = id;
      this.hospitals.value = name;
      if (!this.updateInformation) {
        this.consul.hospital_list_id = id;
        this.consul.name_hospital = name;
        this.consul.address_hospital = address;
        this.consul.phone_hospital = phone;
      } else {
        this.consultations.name_hospital = name;
        this.consultations.hospital_list_id = id;
        this.consultations.address_hospital = address;
        this.consultations.phone_hospital = phone;
      }

      this.show = false;
      await this.$bvModal.hide('modal-prevent-closing-hospital')

    },
    async getAllLaboratoriesAfterCreate(name, address, id, phone) {
      this.show = true;
      const response = await this.findLaboratoriesAll();

      this.laboratories = [];
      this.laboratoriesAll = [];
      for (const a of response.laboratories) {
        this.laboratories.push({
          id: a.id,
          value: a.name,
        })
      }


      const findLaboratory = this.laboratories.find((a) => a.id === id);
      if (!findLaboratory) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No se encontro informacion sobre este laboratorio',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.extra_select_laboratorie.id = id;
      this.extra_select_laboratorie.value = name;
      if (!this.updateInformation) {
        this.consul.order_lab.list_laboratories_id = id;
        this.consul.order_lab.name = name;
        this.consul.order_lab.phone = phone;
        this.consul.order_lab.address = address;
      } else {
        this.consultations.ClinicOrderStudy.list_laboratories_id = id;
        this.consultations.ClinicOrderStudy.name = name;
        this.consultations.ClinicOrderStudy.phone = phone;
        this.consultations.ClinicOrderStudy.address = address;
      }

      this.laboratoriesAll = response.laboratories;
      this.show = false;
      await this.$bvModal.hide('modal-prevent-closing-laboratories')

    },
    async selectDiagnosticsList(idx, id, name) {
      this.consul.diagnostics[idx].diagnostics_list_id = id;
      this.consul.diagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },

    async selectDiagnosticsListEdit(idx, id, name) {
      this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = id;
      this.consultations.ClinicDiagnostics[idx].product = name;
      this.openSelectDiagnostics = null;
    },
    async createLineDiagnostics(idx) {
      if (!this.consul.diagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.openSelectDiagnostics = null;
      this.show = true;
      const send = {
        name: this.consul.diagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consul.diagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineDiagnosticsEdit(idx) {
      if (!this.consultations.ClinicDiagnostics[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo diagnostico es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      this.openSelectDiagnostics = null;
      const send = {
        name: this.consultations.ClinicDiagnostics[idx].product,
      };
      const response = await this.createProductDiagnostics(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicDiagnostics[idx].diagnostics_list_id = response;
        this.openSelectDiagnostics = null;
      } else {
        this.show = false;
        this.openSelectDiagnostics = idx;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchIndications(idx, type) {
      if (type === "create") {
        if (this.consul.recipes[idx].indications) {
          this.openSelectIndications = idx;
          const response = await this.findIndicationsSearch(
              this.consul.recipes[idx].indications
          );
          this.listIndications = [];
          if (response.indications.length > 0) {
            for (const a of response.indications) {
              this.listIndications.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectIndications = null;
        }
      } else {
        if (this.consultations.ClinicRecipes[idx].indications) {
          this.openSelectIndications = idx;
          const response = await this.findIndicationsSearch(
              this.consultations.ClinicRecipes[idx].indications
          );
          this.listIndications = [];
          if (response.indications.length > 0) {
            for (const a of response.indications) {
              this.listIndications.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectIndications = null;
        }
      }
    },
    async searchDiagnostics(idx, type) {
      if (type === "create") {
        if (this.consul.diagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consul.diagnostics[idx].product
          );

          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }

        } else {
          this.openSelectDiagnostics = null;
        }
      } else {
        if (this.consultations.ClinicDiagnostics[idx].product) {
          this.openSelectDiagnostics = idx;
          const response = await this.findDiagnostics(
              this.consultations.ClinicDiagnostics[idx].product
          );
          this.listDiagnostics = [];
          if (response.diagnostics.length > 0) {
            for (const a of response.diagnostics) {
              this.listDiagnostics.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectDiagnostics = null;
        }
      }
    },
    async downloadFileOne(id) {
      await this.downloadFiConsultations(id);
    },
    async selectProductList(idx, id, name) {
      this.consul.recipes[idx].recipe_medicines_id = id;
      this.consul.recipes[idx].product = name;
      this.openSelect = null;
    },
    async selectStudyLab(idx, id, name) {
      this.consul.order_lab.lines[idx].study_lab_id = id;
      this.consul.order_lab.lines[idx].product = name;
      this.openSelectStudies = null;
    },

    async selectStudyLabEdit(idx, id, name) {
      this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].study_lab_id = id;
      this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product = name;
      this.openSelectStudies = null;
    },
    async selectProductListEdit(idx, id, name) {
      this.consultations.ClinicRecipes[idx].recipe_medicines_id = id;
      this.consultations.ClinicRecipes[idx].product = name;
      this.openSelect = null;
    },

    async createLineStudy(idx) {
      if (!this.consul.order_lab.lines[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.order_lab.lines[idx].product,
      };
      const response = await this.createProductStudiesLab(send);
      if (!response.error) {
        this.show = false;
        this.consul.order_lab.lines[idx].study_lab_id = response;
        this.openSelectStudies = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineStudyEdit(idx) {
      if (!this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product,
      };
      const response = await this.createProductStudiesLab(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].study_lab_id = response;
        this.openSelectStudies = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProduct(idx) {
      if (!this.consul.recipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consul.recipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consul.recipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async createLineProductEdit(idx) {
      if (!this.consultations.ClinicRecipes[idx].product) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El campo producto es requerido`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const send = {
        name: this.consultations.ClinicRecipes[idx].product,
      };
      const response = await this.createProductMedicines(send);
      if (!response.error) {
        this.show = false;
        this.consultations.ClinicRecipes[idx].recipe_medicines_id = response;
        this.openSelect = null;
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async searchProducts(idx, type) {
      if (type === "create") {
        if (this.consul.recipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consul.recipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      } else {
        if (this.consultations.ClinicRecipes[idx].product) {
          this.openSelect = idx;
          const response = await this.findMedicinesSearch(
              this.consultations.ClinicRecipes[idx].product
          );
          this.listProducts = [];
          if (response.products.length > 0) {
            for (const a of response.products) {
              this.listProducts.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelect = null;
        }
      }
    },
    async searchStudies(idx, type) {
      if (type === "create") {
        if (this.consul.order_lab.lines[idx].product) {
          this.openSelectStudies = idx;
          const response = await this.findStudiesLab(
              this.consul.order_lab.lines[idx].product
          );
          this.listStudiesLab = [];
          if (response.studies.length > 0) {
            for (const a of response.studies) {
              this.listStudiesLab.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectStudies = null;
        }
      } else {
        if (this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product) {
          this.openSelectStudies = idx;
          const response = await this.findStudiesLab(
              this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].product
          );
          this.listStudiesLab = [];
          if (response.studies.length > 0) {
            for (const a of response.studies) {
              this.listStudiesLab.push({
                id: a.id,
                name: a.name,
              });
            }
          }
        } else {
          this.openSelectStudies = null;
        }
      }
    },
    async getConsultations() {
      this.consults = await this.findPatientAllConsultations(this.patient_id);
      const responseDesc = await this.findPatientAllConsultationsDesc(
          this.patient_id
      );
      this.consul.diagnosis = responseDesc ? responseDesc.diagnosis : null;
    },
    calculateImc() {
      if (!this.consul.height) return;
      if (!this.consul.weight) return;
      const multi = this.consul.height * this.consul.height;
      const total = this.consul.weight / multi;
      this.consul.imc = total.toFixed(2);
      const cm = this.consul.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consul.body_surface = total_body.toFixed(2);
    },
    calculateImcEdit() {
      if (!this.consultations.height) return;
      if (!this.consultations.weight) return;
      const multi = this.consultations.height * this.consultations.height;
      const total = this.consultations.weight / multi;
      this.consultations.imc = total.toFixed(2);
      const cm = this.consultations.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consultations.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consultations.body_surface = total_body.toFixed(2);
    },
    calculateBody() {
      if (!this.consul.height) return;
      if (!this.consul.weight) return;
      const multi = this.consul.height * this.consul.height;
      const total = this.consul.weight / multi;
      this.consul.imc = total.toFixed(2);
      const cm = this.consul.height * 100;
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight;
      this.consul.body_surface = total_body.toFixed(2);
    },
    exportPDFRecipeCreate() {
      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(8);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(`${this.consul.date_consultation}`, 4, 3);
            doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 8,
              },
              bodyStyles: {fontSize: 11},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(`${this.dateStringEs(this.consul.date_consultation)}`, 4, 3);
      doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        body: this.consul.recipes,
      });

      doc.save(`${a}-${b}.pdf`);
    },

    exportPDFRecipe() {
      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: "Desea continuar?",
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Su archivo se va a descargar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4",
            };
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(
                `${this.dateStringEs(this.consultations.date_consultation)}`,
                4,
                3
            );
            doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);

            doc.autoTable({
              columns: [
                {header: "Medicamento", dataKey: "product"},
                {header: "Indicaciones", dataKey: "indications"},
              ],
              startY: 4.5,
              headStyles: {
                fillColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                fontSize: 9,
              },

              bodyStyles: {fontSize: 11},
              body: this.consul.recipes,
            });

            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Cancelled",
              text: "Puede quitar medicamentos",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
        return;
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(
          `${this.dateStringEs(this.consultations.date_consultation)}`,
          4,
          3
      );
      doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);

      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        body: this.consultations.ClinicRecipes,
      });

      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFRecipeDigital() {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consultations.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consultations.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consultations.date_consultation_hours, 1)}`,
          12,
          2.5
      );
      doc.text(`Folio: ${this.consultations.folio ? this.consultations.folio : ""}`, 6, 3.3);
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consultations.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }

      if (this.consultations.ClinicDiagnostics.length > 0) {
        let dataDiagnosis = []
        for (const a of this.consultations.ClinicDiagnostics) {
          if (a.product) {
            dataDiagnosis.push(a.product)
          }
        }
        if (dataDiagnosis.length > 0) {
          let result = dataDiagnosis.map(item => ` - ${item}`).join(',');
          const strArr = doc.splitTextToSize(result ? result : "", 20)
          doc.text('Diagnosticos:', 1, 7.8);
          doc.text(strArr, 1, 8.3);
        }

      }


      let finalY = 9.5;

      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 9.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        body: this.consultations.ClinicRecipes,
        didDrawPage: function (data) {
          finalY = data.cursor.y;
        },
      });

      if (finalY >= 26.7) {
        doc.addPage();
        finalY = 2;
      }


      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {

            finalY += 2;
          }
        }
      }

      const send = {
        folio: this.consultations.folio,
        date_consultation: this.consultations.date_consultation,
        name_doctor: this.consultations.name_doctor,
        permit_no: this.consultations.permit_no,
        vat: this.consultations.vat,
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(this.getUser.name || '', 8, finalY + 2);
      doc.text("AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México", 6.5, finalY + 6);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8.5, finalY + 2.5, 3, 3);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if (this.getUser.address_id) {
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        let responseDataSignature = null
        if (this.consultations.hr_employee_id) {
          this.show = true
          responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false
        }

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 1, footerPosition - 0.5);
          doc.text(footerText, 1, footerPosition - 1);
          doc.text(footerName, 1, footerPosition - 1.5);
          if (responseDataSignature.signatureFile && responseDataSignature) {
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 16, footerPosition - 5, 3, 3);
          }
        }
      };


      await addFooters(doc);

      doc.save(`${a}-${b}.pdf`);
    },


    async exportPDFHospitalCreate() {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Orden de hospitalización`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consul.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consul.date_consultation_hours, 1)}`,
          12.5,
          2.5
      );
      this.consul.folio = dateFolio(this.data_patient.id)
      doc.text(`Folio: ${this.consul.folio}`, 6, 3.3);
      doc.text(`${this.consul.name_doctor ? this.consul.name_doctor : ""}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} / ${this.consul.study_school ? this.consul.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);


      if (this.consul.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }

      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }


      if (this.consul.name_hospital) {
        doc.text(`Nombre del hospital`, 1, 8);
        doc.text(`${this.consul.name_hospital}`, 1, 8.5);
      } else {
        doc.text(`Hospital:`, 1, 8);
      }

      if (this.consul.name_hospital) {
        doc.text(`Dirección de hospital:`, 10, 8);
        doc.text(`${this.consul.address_hospital}`, 10, 8.5);
      } else {
        doc.text(`Dirección de hospital:`, 10, 8);
      }

      if (this.consul.medical_hospitalization) {
        doc.text(`Médico responsable:`, 1, 9.5);
        doc.text(`${this.consul.medical_hospitalization}`, 1, 10.0);
      } else {
        doc.text(`Médico responsable:`, 1, 9.5);
      }

      if (this.consul.date_hospitalization) {
        doc.text(`Fecha ingreso:`, 10, 9.5);
        doc.text(`${getFormat(this.consul.date_hospitalization)}`, 10, 10.0);
      } else {
        doc.text(`Fecha ingreso:`, 10, 9.5);
      }


      let currentHeight = 11;

      if (this.consul.hospitalization) {
        doc.text(`Motivo:`, 1, currentHeight);
        const strArr = doc.splitTextToSize(this.consul.hospitalization, 18);
        currentHeight += 0.5;

        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
            currentHeight = 2;
          }
          doc.text(line, 1, currentHeight);
          currentHeight += 0.7;
        });
      }
      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {

          }

        }

      }

      if (this.checkAndAddNewPage((currentHeight + 4 + 0.5), pageHeight, doc)) {
        currentHeight = 2;
      }
      const send = {
        folio: this.consul.folio ? this.consul.folio : '----',
        date_consultation: this.consul.date_consultation ? this.consul.date_consultation : '----',
        name_doctor: this.consul.name_doctor ? this.consul.name_doctor : '----',
        permit_no: this.consul.permit_no ? this.consul.permit_no : '----',
        vat: this.consul.vat ? this.consul.vat : '----',
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(`${this.consul.name_doctor ? this.consul.name_doctor : ""}`, 8, currentHeight);
      currentHeight += 0.5;
      if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
        currentHeight = 2;
      }
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, currentHeight, 4, 4);
      currentHeight += 4 + 0.5;
      if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
        currentHeight = 2;
      }
      doc.text(`AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México`, 6.5, currentHeight);
      if (this.getUser.department_id === 13) {
        const addFooters = async (doc) => {
          const pageCount = doc.internal.getNumberOfPages();
          let responsePatient;
          if (this.getUser.address_id) {
            responsePatient = await this.findPatientOne(this.getUser.address_id);
          }
          let responseDataSignature = null
          if (this.getUser.id) {
            this.show = true
            responseDataSignature = await this.getFindFullName(this.getUser.id);
            this.show = false
          }

          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field ? this.consul.study_field : ''} `;
            const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
            const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
            const footerPosition = doc.internal.pageSize.getHeight();
            doc.text(footerAddress, 1, footerPosition - 0.5);
            doc.text(footerText, 1, footerPosition - 1);
            doc.text(footerName, 1, footerPosition - 1.5);
            if (responseDataSignature && responseDataSignature.signatureFile) {
              const img2 = new Image();
              img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
              doc.addImage(img2, "png", 16, footerPosition - 5, 3, 3);
            }
          }
        };


        await addFooters(doc);
      }
      this.show = false;
      doc.save(`${a}-${b}.pdf`);
    },

    async exportPDFHospitalUpdate() {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consultations.date_consultation;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Orden de hospitalización`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consultations.date_consultation_hours ? this.consultations.date_consultation_hours : dateTodayFormat())}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${this.consultations.date_consultation_hours ? sumMonthsHours(this.consultations.date_consultation_hours, 1) : sumMonthsHours(dateTodayFormat(), 1)}`,
          12,
          2.5
      );
      doc.text(`Folio: ${this.consultations.folio}`, 6, 3.3);
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consultations.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }


      if (this.consultations.name_hospital) {
        doc.text(`Nombre del hospital`, 1, 8);
        doc.text(`${this.consultations.name_hospital}`, 1, 8.5);
      } else {
        doc.text(`Hospital:`, 1, 8);
      }

      if (this.consultations.name_hospital) {
        doc.text(`Dirección de hospital:`, 10, 8);
        doc.text(`${this.consultations.address_hospital}`, 10, 8.5);
      } else {
        doc.text(`Dirección de hospital:`, 10, 8);
      }

      if (this.consultations.medical_hospitalization) {
        doc.text(`Médico responsable:`, 1, 9.5);
        doc.text(`${this.consultations.medical_hospitalization}`, 1, 10.0);
      } else {
        doc.text(`Médico responsable:`, 1, 9.5);
      }

      if (this.consultations.date_hospitalization) {
        doc.text(`Fecha ingreso:`, 10, 9.5);
        doc.text(`${getFormat(this.consultations.date_hospitalization)}`, 10, 10.0);
      } else {
        doc.text(`Fecha ingreso:`, 10, 9.5);
      }

      let currentHeight = 11;

      if (this.consultations.hospitalization) {
        doc.text(`Motivo:`, 1, currentHeight);
        const strArr = doc.splitTextToSize(this.consultations.hospitalization, 18);
        currentHeight += 0.5;

        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
            currentHeight = 2;
          }
          doc.text(line, 1, currentHeight);
          currentHeight += 0.7;
        });
      }
      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {

          }

        }

      }

      if (this.checkAndAddNewPage((currentHeight + 4 + 0.5), pageHeight, doc)) {
        currentHeight = 2;
      }
      const send = {
        folio: this.consultations.folio ? this.consultations.folio : '----',
        date_consultation: this.consultations.date_consultation ? this.consultations.date_consultation : '----',
        name_doctor: this.consultations.name_doctor ? this.consultations.name_doctor : '----',
        permit_no: this.consultations.permit_no ? this.consultations.permit_no : '----',
        vat: this.consultations.vat ? this.consultations.vat : '----',
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(`${this.consultations.name_doctor ? this.consultations.name_doctor : ""}`, 8, currentHeight);
      currentHeight += 0.5;
      if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
        currentHeight = 2;
      }
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, currentHeight, 4, 4);
      currentHeight += 4 + 0.5;
      if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
        currentHeight = 2;
      }
      doc.text(`AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México`, 6.5, currentHeight);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if (this.getUser.address_id) {
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        let responseDataSignature = null;
        if (this.consultations.hr_employee_id) {
          this.show = true
          responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false
        }

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 1, footerPosition - 0.5);
          doc.text(footerText, 1, footerPosition - 1);
          doc.text(footerName, 1, footerPosition - 1.5);
          if (responseDataSignature && responseDataSignature.signatureFile) {
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
          }
        }
      };


      await addFooters(doc);
      doc.save(`${a}-${b}.pdf`);
    },

    async exportPDFRecipeCreateDigital() {

      this.show = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(this.consul.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(this.consul.date_consultation_hours, 1)}`,
          12.5,
          2.5
      );
      this.consul.folio = dateFolio(this.data_patient.id)
      doc.text(`Folio: ${this.consul.folio ? this.consul.folio : ''}`, 6, 3.3);
      doc.text(`${this.consul.name_doctor}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} / ${this.consul.study_school ? this.consul.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (this.consul.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }


      if (this.consul.diagnostics.length > 0) {
        let dataDiagnosis = []
        for (const a of this.consul.diagnostics) {
          if (a.product) {
            dataDiagnosis.push(a.product)
          }
        }
        if (dataDiagnosis.length > 0) {
          let result = dataDiagnosis.map(item => ` - ${item}`).join(',');
          const strArr = doc.splitTextToSize(result ? result : "", 17)
          doc.text('Diagnosticos:', 1, 7.8);
          doc.text(strArr, 1, 8.3);
        }

      }


      let finalY = 9.5;

      doc.autoTable({
        columns: [
          {header: "Medicamento", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        startY: 9.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        body: this.consul.recipes,
        didDrawPage: function (data) {
          finalY = data.cursor.y;
        },
      });

      if (finalY >= 26.7) {
        doc.addPage();
        finalY = 2;
      }


      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee) {
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature) {

            finalY += 2;
          }
        }
      }

      const send = {
        folio: this.consul.folio,
        date_consultation: this.consul.date_consultation,
        name_doctor: this.consul.name_doctor,
        permit_no: this.consul.permit_no,
        vat: this.consul.vat,
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(this.consul.name_doctor || '', 8, finalY + 2);
      doc.text("AVENIDA LA PAZ 33, 28017 COLIMA, Colima, México", 6.5, finalY + 6);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8.5, finalY + 2.5, 3, 3);
      if (this.getUser.department_id === 13) {
        const addFooters = async (doc) => {
          const pageCount = doc.internal.getNumberOfPages();
          let responsePatient;
          if (this.getUser.address_id) {
            responsePatient = await this.findPatientOne(this.getUser.address_id);
          }

          let responseDataSignature = null
          if (this.getUser.id) {
            this.show = true
            responseDataSignature = await this.getFindFullName(this.getUser.id);
            this.show = false
          }
          console.log(this.consul.name_doctor)

          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field ? this.consul.study_field : ''} `;
            const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
            const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
            const footerPosition = doc.internal.pageSize.getHeight();
            doc.text(footerAddress, 1, footerPosition - 0.5);
            doc.text(footerText, 1, footerPosition - 1);
            doc.text(footerName, 1, footerPosition - 1.5);
            if (responseDataSignature && responseDataSignature.signatureFile) {
              const img2 = new Image();
              img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
              doc.addImage(img2, "png", 16, footerPosition - 5, 3, 3);
            }
          }
        };

        await addFooters(doc);
      }
      this.show = false;
      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFSummaryUpdate() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(
          `Nombre del médico: ${this.consultations.name_doctor ? this.consultations.name_doctor : ""} - Última consulta: - consulta: ${formatHour(this.consultations.date_consultation_hours)}`,
          40,
          40
      );

      doc.setFont("helvetica", "bold");
      doc.setFontSize(15);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.setDrawColor(155, 155, 155);

      const x1 = 40;
      const y1 = 75;
      const lineWidth = 2;


      doc.setLineWidth(lineWidth);
      const x2 = 550;
      const y2 = 75;

      doc.line(x1, y1, x2, y2);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Resumen de consulta`, 40, 95);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 115);

      if (this.consul.years) {
        doc.text(`Edad: ${this.consultations.years}`, 40, 135);
      } else {
        doc.text(`Edad:`, 40, 135);
      }

      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 120, 135);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 220, 135);
      } else {
        doc.text(`Fecha Nacimiento:`, 120, 135);
      }

      doc.text(`Consultorio de mi paciente: ONKIMIA`, 40, 155);

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Antecedentes importantes`, 40, 175);
      const y3 = 180;
      const y4 = 180;
      doc.line(x1, y3, x2, y4);
      const responseInfo = await this.findPatientOneInformation(this.patient_id);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const strArrBack = doc.splitTextToSize(responseInfo.important_background ? responseInfo.important_background : '', 500)
      const yOffset = 210;

      doc.text(strArrBack, 40, yOffset);

      const firstParaHeight = doc.getTextDimensions(strArrBack, {fontSize: doc.internal.getFontSize()}).h;

      const marginBetweenParagraphs = 10;

      const yOffsetSecondPara = yOffset + firstParaHeight + marginBetweenParagraphs + 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Signos Vitales/Básicos", 40, yOffsetSecondPara);
      const y5 = yOffsetSecondPara + 10;
      const y6 = yOffsetSecondPara + 10;
      doc.line(x1, y5, x2, y6);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Altura:`, 40, yOffsetSecondPara + 35);
      doc.text(`Peso:`, 115, yOffsetSecondPara + 35);
      doc.text(`I.M.C.:`, 190, yOffsetSecondPara + 35);
      doc.text(`S.C.T.:`, 265, yOffsetSecondPara + 35);
      doc.setFont("helvetica", "bold");
      doc.text(`${this.consultations.height ? this.consultations.height + " " + 'Mts' : "----"}`, 70, yOffsetSecondPara + 35);
      doc.text(`${this.consultations.weight ? this.consultations.weight + " " + 'kg' : "----"}`, 145, yOffsetSecondPara + 35);
      doc.text(`${this.consultations.imc ? this.consultations.imc : "----"}`, 225, yOffsetSecondPara + 35);
      doc.text(`${this.consultations.body_surface ? this.consultations.body_surface : "----"}`, 300, yOffsetSecondPara + 35);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Diagnósticos", 40, yOffsetSecondPara + 60);
      const y7 = yOffsetSecondPara + 70;
      const y8 = yOffsetSecondPara + 70;
      doc.line(x1, y7, x2, y8);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const concatenatedText = this.consultations.ClinicDiagnostics
          .filter(item => item.product)
          .map(item => `- ${item.product}`)
          .join('\n');
      const strArrBackDiagnostics = doc.splitTextToSize(concatenatedText, 500)
      doc.text(strArrBackDiagnostics, 40, yOffsetSecondPara + 100);
      const yOffSetDiagSpace = yOffsetSecondPara + 100;
      const twoParaHeight = doc.getTextDimensions(strArrBackDiagnostics, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetDiag = yOffSetDiagSpace + twoParaHeight + marginBetweenParagraphs + 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Medicamentos", 40, yOffsetDiag);
      const y9 = yOffsetDiag + 10;
      const y10 = yOffsetDiag + 10;
      doc.line(x1, y9, x2, y10);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const concatenatedMedicals = this.consultations.ClinicRecipes
          .filter(item => item.product)
          .map(item => `${item.indications} - ${item.product}`)
          .join('\n');
      const strArrBackMedicals = doc.splitTextToSize(concatenatedMedicals, 500)
      doc.text(strArrBackMedicals, 40, yOffsetDiag + 30);
      const yOffSetRecipeText = yOffsetDiag + 10;
      const threeParaHeight = doc.getTextDimensions(strArrBackMedicals, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetRecipe = yOffSetRecipeText + threeParaHeight + marginBetweenParagraphs + 10
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Tratamiento oncológico sugerido", 40, yOffsetRecipe + 10);
      const y11 = yOffsetRecipe + 20;
      const y12 = yOffsetRecipe + 20;
      doc.line(x1, y11, x2, y12);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Diagnóstico molecular: ${this.consultations.ClinicOncologicalTreatment.molecular_diagnosis ? 'Si' : 'No'} `, 40, yOffsetRecipe + 35);
      doc.text(`Tratamiento`, 40, yOffsetRecipe + 50);

      const concatenatedTreatment = this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines
          .filter(item => item.treatment)
          .map(item => `${item.description} - ${item.treatment ? item.treatment : ""}`)
          .join('\n');
      const strArrBackTreatment = doc.splitTextToSize(concatenatedTreatment, 500)
      doc.text(strArrBackTreatment, 40, yOffsetRecipe + 65);
      const yOffSetTreatmentText = yOffsetRecipe + 50;
      const fourParaHeight = doc.getTextDimensions(strArrBackTreatment, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetTreatments = yOffSetTreatmentText + fourParaHeight + marginBetweenParagraphs + 10
      doc.text(`Línea de tratamiento: ${this.consultations.ClinicOncologicalTreatment.line_treatment ? this.consultations.ClinicOncologicalTreatment.line_treatment : "----"}`, 40, yOffsetTreatments);
      doc.text(`Respuesta de tratamiento: ${this.consultations.ClinicOncologicalTreatment.answer_treatment ? this.consultations.ClinicOncologicalTreatment.answer_treatment : "----"}`, 170, yOffsetTreatments);

      const strArrBackSpecs = doc.splitTextToSize(this.consultations.ClinicOncologicalTreatment.specifications ? this.consultations.ClinicOncologicalTreatment.specifications : "", 500)

      const yOffSetText = yOffsetRecipe + 50;
      const fiveParaHeight = doc.getTextDimensions(strArrBackSpecs, {fontSize: doc.internal.getFontSize()}).h;
      const pageHeight = doc.internal.pageSize.height;


      const yOffsetDescription = yOffSetText + fiveParaHeight + marginBetweenParagraphs + 10
      const availableSpace = pageHeight - yOffsetDescription;
      const totalPage = pageHeight - availableSpace;
      if (totalPage < 700) {
        doc.text(`Especificaciones:`, 40, yOffsetTreatments + 15);
        doc.text(strArrBackSpecs, 40, yOffsetTreatments + 25);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("Estudios Requeridos", 40, yOffsetDescription + 70);
        const y13 = yOffsetDescription + 80;
        const y14 = yOffsetDescription + 80;
        doc.line(x1, y13, x2, y14);
        doc.text("Orden de laboratorio", 40, yOffsetDescription + 100);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const concatenatedStudies = this.consultations.ClinicOrderStudy.ClinicStudiesLines
            .filter(item => item.product)
            .map(item => ` - ${item.product ? item.product : ""}`)
            .join('\n');
        const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, 500)
        doc.text(strArrBackStudies, 40, yOffsetDescription + 120);
      } else {
        const offSetPage = 10
        doc.addPage();
        doc.text(`Especificaciones:`, 40, offSetPage + 40);
        doc.text(strArrBackSpecs, 40, offSetPage + 60);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("Estudios Requeridos", 40, 100 + fiveParaHeight);
        const y13 = offSetPage + 100 + fiveParaHeight;
        const y14 = offSetPage + 100 + fiveParaHeight;
        doc.line(x1, y13, x2, y14);
        doc.text("Orden de laboratorio", 40, 140 + fiveParaHeight);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const concatenatedStudies = this.consultations.ClinicOrderStudy.ClinicStudiesLines
            .filter(item => item.product)
            .map(item => ` - ${item.product ? item.product : ""}`)
            .join('\n');
        const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, 500)
        doc.text(strArrBackStudies, 40, 160 + fiveParaHeight);
      }

      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if (this.getUser.address_id) {
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }

        this.show = true
        const responseDataSignature = await this.getFindFullName(this.getUser.id);
        this.show = false


        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 15, footerPosition - 25);
          doc.text(footerText, 15, footerPosition - 40);
          doc.text(footerName, 15, footerPosition - 55);
          if (responseDataSignature && responseDataSignature.signatureFile) {
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
          }
        }
      };

      await addFooters(doc);

      const offSetPage = 10
      doc.addPage();
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Análisis:`, 40, offSetPage + 40);
      const y1P2 = offSetPage + 50;
      const y2P2 = offSetPage + 50;
      doc.line(x1, y1P2, x2, y2P2);
      const therapeuticPlan = this.consul.analysis_and_plan || '';
      const maxWidth = 500;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitTherapeuticPlanText = doc.splitTextToSize(therapeuticPlan, maxWidth);
      doc.text(splitTherapeuticPlanText, 40, y1P2 + 20);
      const p2FirtsParaHeight = doc.getTextDimensions(splitTherapeuticPlanText, {fontSize: doc.internal.getFontSize()}).h;
      const marginBetweenParagraphsP2 = 20;
      const yOffsetAnalysisAndPlan = y1P2 + p2FirtsParaHeight + offSetPage + marginBetweenParagraphsP2;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Plan Terapéutico:", 40, yOffsetAnalysisAndPlan);
      const y3P2 = yOffsetAnalysisAndPlan + 10;
      const y4P2 = yOffsetAnalysisAndPlan + 10;
      doc.line(x1, y3P2, x2, y4P2);
      const extraIndications = this.consul.therapeutic_plan || '';
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitTextExtraIndications = doc.splitTextToSize(extraIndications, maxWidth);
      doc.text(splitTextExtraIndications, 40, y3P2 + 20);
      const p2SecondParaHeight = doc.getTextDimensions(splitTherapeuticPlanText, {fontSize: doc.internal.getFontSize()}).h;
      const yOffsetTherapeuticPlan = y3P2 + p2SecondParaHeight + offSetPage + marginBetweenParagraphsP2;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Indicaciones Extras:", 40, yOffsetTherapeuticPlan);
      const y5P2 = yOffsetTherapeuticPlan + 10;
      const y6P2 = yOffsetTherapeuticPlan + 10;
      doc.line(x1, y5P2, x2, y6P2);
      const analysisAndPlan = this.consul.extra_indications || '';
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitanalysisAndPlan = doc.splitTextToSize(analysisAndPlan, maxWidth);
      doc.text(splitanalysisAndPlan, 40, y5P2 + 20);

      await addFooters(doc);

      doc.save(
          `${this.data_patient.name}-${this.consul.date_consultation}.pdf`
      );
    },
    async exportPDFSummary() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(
          `Nombre del médico: ${this.consul.name_doctor ? this.consul.name_doctor : ""} - Última consulta: - consulta: ${formatHour(this.consul.date_consultation_hours)}`,
          40,
          40
      );

      doc.setFont("helvetica", "bold");
      doc.setFontSize(15);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.setDrawColor(155, 155, 155);

      const x1 = 40;
      const y1 = 75;
      const lineWidth = 2;


      doc.setLineWidth(lineWidth);
      const x2 = 550;
      const y2 = 75;

      doc.line(x1, y1, x2, y2);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Resumen de consulta`, 40, 95);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 115);

      if (this.consul.years) {
        doc.text(`Edad: ${this.consul.years}`, 40, 135);
      } else {
        doc.text(`Edad:`, 40, 135);
      }

      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 120, 135);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 220, 135);
      } else {
        doc.text(`Fecha Nacimiento:`, 120, 135);
      }

      doc.text(`Consultorio de mi paciente: ONKIMIA`, 40, 155);

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Antecedentes importantes`, 40, 175);
      const y3 = 180;
      const y4 = 180;
      doc.line(x1, y3, x2, y4);
      const responseInfo = await this.findPatientOneInformation(this.patient_id);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const strArrBack = doc.splitTextToSize(responseInfo.important_background ? responseInfo.important_background : '', 500)
      const yOffset = 210;

      doc.text(strArrBack, 40, yOffset);

      const firstParaHeight = doc.getTextDimensions(strArrBack, {fontSize: doc.internal.getFontSize()}).h;

      const marginBetweenParagraphs = 10;

      const yOffsetSecondPara = yOffset + firstParaHeight + marginBetweenParagraphs + 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Signos Vitales/Básicos", 40, yOffsetSecondPara);
      const y5 = yOffsetSecondPara + 10;
      const y6 = yOffsetSecondPara + 10;
      doc.line(x1, y5, x2, y6);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Altura:`, 40, yOffsetSecondPara + 35);
      doc.text(`Peso:`, 115, yOffsetSecondPara + 35);
      doc.text(`I.M.C.:`, 190, yOffsetSecondPara + 35);
      doc.text(`S.C.T.:`, 265, yOffsetSecondPara + 35);
      doc.setFont("helvetica", "bold");
      doc.text(`${this.consul.height ? this.consul.height + " " + 'Mts' : "----"}`, 70, yOffsetSecondPara + 35);
      doc.text(`${this.consul.weight ? this.consul.weight + " " + 'kg' : "----"}`, 145, yOffsetSecondPara + 35);
      doc.text(`${this.consul.imc ? this.consul.imc : "----"}`, 225, yOffsetSecondPara + 35);
      doc.text(`${this.consul.body_surface ? this.consul.body_surface : "----"}`, 300, yOffsetSecondPara + 35);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Diagnósticos", 40, yOffsetSecondPara + 60);
      const y7 = yOffsetSecondPara + 70;
      const y8 = yOffsetSecondPara + 70;
      doc.line(x1, y7, x2, y8);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const concatenatedText = this.consul.diagnostics
          .filter(item => item.product)
          .map(item => `- ${item.product}`)
          .join('\n');
      const strArrBackDiagnostics = doc.splitTextToSize(concatenatedText, 500)
      doc.text(strArrBackDiagnostics, 40, yOffsetSecondPara + 100);
      const yOffSetDiagSpace = yOffsetSecondPara + 100;
      const twoParaHeight = doc.getTextDimensions(strArrBackDiagnostics, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetDiag = yOffSetDiagSpace + twoParaHeight + marginBetweenParagraphs + 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Medicamentos", 40, yOffsetDiag);
      const y9 = yOffsetDiag + 10;
      const y10 = yOffsetDiag + 10;
      doc.line(x1, y9, x2, y10);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const concatenatedMedicals = this.consul.recipes
          .filter(item => item.product)
          .map(item => `${item.indications} - ${item.product}`)
          .join('\n');
      const strArrBackMedicals = doc.splitTextToSize(concatenatedMedicals, 500)
      doc.text(strArrBackMedicals, 40, yOffsetDiag + 30);
      const yOffSetRecipeText = yOffsetDiag + 10;
      const threeParaHeight = doc.getTextDimensions(strArrBackMedicals, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetRecipe = yOffSetRecipeText + threeParaHeight + marginBetweenParagraphs + 10
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Tratamiento oncológico sugerido", 40, yOffsetRecipe + 10);
      const y11 = yOffsetRecipe + 20;
      const y12 = yOffsetRecipe + 20;
      doc.line(x1, y11, x2, y12);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Diagnóstico molecular: ${this.consul.oncologicalTretaments.molecular_diagnosis ? 'Si' : 'No'} `, 40, yOffsetRecipe + 35);
      doc.text(`Tratamiento`, 40, yOffsetRecipe + 50);

      const concatenatedTreatment = this.consul.oncologicalTretaments.oncologicalLines
          .filter(item => item.treatment)
          .map(item => `${item.description} - ${item.treatment.value ? item.treatment.value : ""}`)
          .join('\n');
      const strArrBackTreatment = doc.splitTextToSize(concatenatedTreatment, 500)
      doc.text(strArrBackTreatment, 40, yOffsetRecipe + 65);
      const yOffSetTreatmentText = yOffsetRecipe + 50;
      const fourParaHeight = doc.getTextDimensions(strArrBackTreatment, {fontSize: doc.internal.getFontSize()}).h;

      const yOffsetTreatments = yOffSetTreatmentText + fourParaHeight + marginBetweenParagraphs + 10
      doc.text(`Línea de tratamiento: ${this.consul.oncologicalTretaments.line_treatment ? this.consul.oncologicalTretaments.line_treatment : "----"}`, 40, yOffsetTreatments);
      doc.text(`Respuesta de tratamiento: ${this.consul.oncologicalTretaments.answer_treatment ? this.consul.oncologicalTretaments.answer_treatment : "----"}`, 170, yOffsetTreatments);

      const strArrBackSpecs = doc.splitTextToSize(this.consul.oncologicalTretaments.specifications ? this.consul.oncologicalTretaments.specifications : "", 500)

      const yOffSetText = yOffsetRecipe + 50;
      const fiveParaHeight = doc.getTextDimensions(strArrBackSpecs, {fontSize: doc.internal.getFontSize()}).h;
      const pageHeight = doc.internal.pageSize.height;


      const yOffsetDescription = yOffSetText + fiveParaHeight + marginBetweenParagraphs + 10
      const availableSpace = pageHeight - yOffsetDescription;
      const totalPage = pageHeight - availableSpace;
      if (totalPage < 700) {
        doc.text(`Especificaciones:`, 40, yOffsetTreatments + 15);
        doc.text(strArrBackSpecs, 40, yOffsetTreatments + 25);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("Estudios Requeridos", 40, yOffsetDescription + 70);
        const y13 = yOffsetDescription + 80;
        const y14 = yOffsetDescription + 80;
        doc.line(x1, y13, x2, y14);
        doc.text("Orden de laboratorio", 40, yOffsetDescription + 100);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const concatenatedStudies = this.consul.order_lab.lines
            .filter(item => item.product)
            .map(item => ` - ${item.product ? item.product : ""}`)
            .join('\n');
        const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, 500)
        doc.text(strArrBackStudies, 40, yOffsetDescription + 120);
      } else {
        const offSetPage = 10
        doc.addPage();
        doc.text(`Especificaciones:`, 40, offSetPage + 40);
        doc.text(strArrBackSpecs, 40, offSetPage + 60);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("Estudios Requeridos", 40, 100 + fiveParaHeight);
        const y13 = offSetPage + 100 + fiveParaHeight;
        const y14 = offSetPage + 100 + fiveParaHeight;
        doc.line(x1, y13, x2, y14);
        doc.text("Orden de laboratorio", 40, 140 + fiveParaHeight);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const concatenatedStudies = this.consul.order_lab.lines
            .filter(item => item.product)
            .map(item => ` - ${item.product ? item.product : ""}`)
            .join('\n');
        const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, 500)
        doc.text(strArrBackStudies, 40, 160 + fiveParaHeight);

      }
      if (this.getUser.department_id === 13) {
        const addFooters = async (doc) => {
          const pageCount = doc.internal.getNumberOfPages();
          let responsePatient;
          if (this.getUser.address_id) {
            responsePatient = await this.findPatientOne(this.getUser.address_id);
          }

          this.show = true
          const responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false


          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field ? this.consul.study_field : ''} `;
            const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
            const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
            const footerPosition = doc.internal.pageSize.getHeight();
            doc.text(footerAddress, 15, footerPosition - 25);
            doc.text(footerText, 15, footerPosition - 40);
            doc.text(footerName, 15, footerPosition - 55);
            if (responseDataSignature && responseDataSignature.signatureFile) {
              const img2 = new Image();
              img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
              doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
            }
          }
        };

        await addFooters(doc);
      }

      const offSetPage = 10
      doc.addPage();
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Análisis:`, 40, offSetPage + 40);
      const y1P2 = offSetPage + 50;
      const y2P2 = offSetPage + 50;
      doc.line(x1, y1P2, x2, y2P2);
      const therapeuticPlan = this.consul.analysis_and_plan || '';
      const maxWidth = 500;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitTherapeuticPlanText = doc.splitTextToSize(therapeuticPlan, maxWidth);
      doc.text(splitTherapeuticPlanText, 40, y1P2 + 20);
      const p2FirtsParaHeight = doc.getTextDimensions(splitTherapeuticPlanText, {fontSize: doc.internal.getFontSize()}).h;
      const marginBetweenParagraphsP2 = 20;
      const yOffsetAnalysisAndPlan = y1P2 + p2FirtsParaHeight + offSetPage + marginBetweenParagraphsP2;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Plan Terapéutico:", 40, yOffsetAnalysisAndPlan);
      const y3P2 = yOffsetAnalysisAndPlan + 10;
      const y4P2 = yOffsetAnalysisAndPlan + 10;
      doc.line(x1, y3P2, x2, y4P2);
      const extraIndications = this.consul.therapeutic_plan || '';
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitTextExtraIndications = doc.splitTextToSize(extraIndications, maxWidth);
      doc.text(splitTextExtraIndications, 40, y3P2 + 20);
      const p2SecondParaHeight = doc.getTextDimensions(splitTherapeuticPlanText, {fontSize: doc.internal.getFontSize()}).h;
      const yOffsetTherapeuticPlan = y3P2 + p2SecondParaHeight + offSetPage + marginBetweenParagraphsP2;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Indicaciones Extras:", 40, yOffsetTherapeuticPlan);
      const y5P2 = yOffsetTherapeuticPlan + 10;
      const y6P2 = yOffsetTherapeuticPlan + 10;
      doc.line(x1, y5P2, x2, y6P2);
      const analysisAndPlan = this.consul.extra_indications || '';
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const splitanalysisAndPlan = doc.splitTextToSize(analysisAndPlan, maxWidth);
      doc.text(splitanalysisAndPlan, 40, y5P2 + 20);


      doc.save(
          `${this.data_patient.name}-${this.consul.date_consultation}.pdf`
      );
    },
    async exportPDFStudiesCreate() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      img.src = logo;
      const pageHeight = doc.internal.pageSize.height;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Estudios de laboratorio`, 225, 50);
      doc.setFontSize(10);
      doc.text(
          `Fecha: ${formatHour(this.consul.date_consultation_hours)}`,
          400,
          50
      );
      doc.text(`${this.consul.name_doctor ? this.consul.name_doctor : ""}`, 40, 120);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          340,
          120
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 40, 140);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} / ${this.consul.study_school ? this.consul.study_school : ''}`, 40, 165);
      doc.text(`Nombre Paciente:`, 40, 190);
      doc.text(`${this.data_patient.name}`, 40, 205);
      doc.text(`Paciente:`, 250, 190);
      const list = '';
      if(this.data_patient.property_product_pricelist === 73){
        doc.text('F', 265, 205);
      }else if(this.data_patient.property_product_pricelist === 131){
        doc.text('C', 265, 205);
      }else {
        doc.text('R', 265, 205);
      }


      if (this.consul.years) {
        doc.text(`Edad:`, 340, 190);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 340, 205);
      } else {
        doc.text(`Edad:`, 340, 190);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 400, 190);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 400, 205);
      } else {
        doc.text(`Fecha Nacimiento:`, 400, 190);
      }
      doc.text(`Telefonó Paciente:`, 40, 225);
      doc.text(`${this.data_patient.mobile}`, 40, 240);
      doc.text(`Correo Electronico:`, 200, 225);
      doc.text(`${this.data_patient.email}`, 200, 240);
      doc.text(`Medico tratante:`, 340, 225);
      doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 340, 240);
      doc.text(`Laboratorio: ${this.consul.order_lab.name ? this.consul.order_lab.name : ""}`, 40, 265);
      doc.text(`Dirección: ${this.consul.order_lab.address ? this.consul.order_lab.address : ""}`, 40, 285);
      doc.text(`Telefono: ${this.consul.order_lab.phone ? this.consul.order_lab.phone : ""}`, 40, 305);
      let textDiagnosis = null;
      if (this.consul.diagnostics.length > 0) {
        const dataTextDiagnosis = this.consul.diagnostics
            .map(item => item.product)
            .filter(productName => productName !== '')
            .join(',');

        textDiagnosis = doc.splitTextToSize(dataTextDiagnosis ? dataTextDiagnosis : "", 400)
      }
      doc.text(`Diagnostico:`, 40, 330);
      doc.text(textDiagnosis, 40, 340);
      doc.text(`Observaciones:`, 40, 370);
      const observationsText = this.consul.order_lab.observations ? this.consul.order_lab.observations : "";
      const strArrBack = doc.splitTextToSize(observationsText, 500);
      let currentHeight = 390;

      strArrBack.forEach((line, index) => {
        if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
          doc.text(`Observaciones:`, 40, 40);
          currentHeight = 70;
        }
        doc.text(line, 40, currentHeight);
        currentHeight += 15;
      });

      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }

      doc.autoTable({
        columns: [
          {header: "Estudio", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        body: this.consul.order_lab.lines,
        startY: currentHeight,
      });

      currentHeight = doc.lastAutoTable.finalY + 20;

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }
      const send = {
        date_consultation: this.consul.date_consultation ? this.consul.date_consultation : "---",
        name_doctor: this.consul.name_doctor ? this.consul.name_doctor : "---",
        permit_no: this.consul.permit_no ? this.consul.permit_no : "---",
        vat: this.consul.vat ? this.consul.vat : "---",
      }
      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 90, 90);
      if (this.getUser.department_id === 13) {
        const addFooters = async (doc) => {
          const pageCount = doc.internal.getNumberOfPages();
          let responsePatient;
          if (this.getUser.address_id) {
            responsePatient = await this.findPatientOne(this.getUser.address_id);
          }

          this.show = true
          const responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false


          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field ? this.consul.study_field : ''} `;
            const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
            const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
            const footerPosition = doc.internal.pageSize.getHeight();
            doc.text(footerAddress, 15, footerPosition - 25);
            doc.text(footerText, 15, footerPosition - 40);
            doc.text(footerName, 15, footerPosition - 55);
            if (responseDataSignature && responseDataSignature.signatureFile) {
              const img2 = new Image();
              img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
              doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
            }
          }
        };

        await addFooters(doc);
      }
      this.show = false;
      doc.save(
          `${this.data_patient.name}-${this.consul.date_consultation}.pdf`
      );
    },

    async exportPDFStudies() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      img.src = logo;
      const pageHeight = doc.internal.pageSize.height;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Estudios de Laboratorio`, 225, 50);
      doc.setFontSize(10);
      doc.text(
          `Fecha: ${this.consultations.date_consultation_hours ? formatHour(this.consultations.date_consultation_hours) : dateStringMonth()}`,
          400,
          50
      );
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 40, 120);
      doc.text(
          `RFC: ${
              this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth.vat : ""
          }`,
          340,
          120
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 40, 140);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 40, 165);
      doc.text(`Nombre Paciente:`, 40, 190);
      doc.text(`${this.data_patient.name}`, 40, 205);
      doc.text(`Paciente:`, 250, 190);
      const list = '';
      if(this.data_patient.property_product_pricelist === 73){
        doc.text('F', 265, 205);
      }else if(this.data_patient.property_product_pricelist === 131){
        doc.text('C', 265, 205);
      }else {
        doc.text('R', 265, 205);
      }

      if (this.consul.years) {
        doc.text(`Edad:`, 340, 190);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 340, 205);
      } else {
        doc.text(`Edad:`, 340, 190);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 400, 190);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 400, 205);
      } else {
        doc.text(`Fecha Nacimiento:`, 400, 190);
      }
      doc.text(`Telefonó Paciente:`, 40, 225);
      doc.text(`${this.data_patient.mobile}`, 40, 240);
      doc.text(`Correo Electronico:`, 200, 225);
      doc.text(`${this.data_patient.email}`, 200, 240);
      doc.text(`Medico tratante:`, 340, 225);
      doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 340, 240);
      doc.text(`Laboratorio: ${this.consultations.ClinicOrderStudy.name ? this.consultations.ClinicOrderStudy.name : ""}`, 40, 265);
      doc.text(`Dirección: ${this.consultations.ClinicOrderStudy.address ? this.consultations.ClinicOrderStudy.address : ""}`, 40, 285);
      doc.text(`Telefono: ${this.consultations.ClinicOrderStudy.phone ? this.consultations.ClinicOrderStudy.phone : ""}`, 40, 305);


      let textDiagnosis = null;
      if (this.consultations.ClinicDiagnostics.length > 0) {
        const dataTextDiagnosis = this.consultations.ClinicDiagnostics
            .map(item => item.product)
            .filter(productName => productName !== '')
            .join(',');

        textDiagnosis = doc.splitTextToSize(dataTextDiagnosis ? dataTextDiagnosis : "", 400)
      }

      doc.text(`Diagnostico:`, 40, 330);
      doc.text(textDiagnosis, 40, 340);
      doc.text(`Observaciones:`, 40, 370);
      const observationsText = this.consultations.ClinicOrderStudy.observations ? this.consultations.ClinicOrderStudy.observations : "";
      const strArrBack = doc.splitTextToSize(observationsText, 500);
      let currentHeight = 390;


      strArrBack.forEach((line, index) => {
        if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
          doc.text(`Observaciones:`, 40, 40);
          currentHeight = 70;
        }
        doc.text(line, 40, currentHeight);
        currentHeight += 15;
      });

      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }


      doc.autoTable({
        columns: [
          {header: "Estudio", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        body: this.consultations.ClinicOrderStudy.ClinicStudiesLines,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 9},
        bodyStyles: {fontSize: 11},
        startY: currentHeight,
      });
      currentHeight = doc.lastAutoTable.finalY + 20;

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }
      const send = {
        date_consultation: this.consultations.date_consultation ? this.consultations.date_consultation : "---",
        name_doctor: this.consultations.name_doctor ? this.consultations.name_doctor : "---",
        permit_no: this.consultations.permit_no ? this.consultations.permit_no : "---",
        vat: this.consultations.vat ? this.consultations.vat : "---",
      }
      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 90, 90);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if (this.consultations.hr_employee_id) {
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        let responseDataSignature = null;
        if (this.consultations.hr_employee_id) {
          this.show = true
          responseDataSignature = await this.getFindFullName(this.getUser.id);
          this.show = false
        }


        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 40, footerPosition - 25);
          doc.text(footerText, 40, footerPosition - 40);
          doc.text(footerName, 40, footerPosition - 55);
          if (responseDataSignature && responseDataSignature.signatureFile) {
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
          }

        }
      };

      await addFooters(doc);
      doc.save(
          `${this.data_patient.name}-${this.consultations.date_consultation ? this.consultations.date_consultation : dateStringMonth()}.pdf`
      );
    },
    async newConsultations() {
      this.consultations.ClinicOrderStudy.recommend_lab = false;
      this.createConsultations = true;
      this.updateInformation = false;
      this.consul.order_lab.recommend_lab = false;
      await this.getAllLaboratories();
      await this.listHospitalGets();
      await this.listAnswerTreatments();
      await this.listLineTreatments();
      await this.listAllTreatments();
    },
    changeDiagnostic(state) {
      this.consul.order_lab.include_diagnostic = state;
      let resultName = []
      if (this.consul.diagnostics.length > 0) {
        for (const a of this.consul.diagnostics) {
          if (a.product) {
            resultName.push(a.product)
          }

        }
        if (resultName.length >= 0) {
          this.consul.order_lab.diagnostic = resultName.map(item => `${item}`).join(',');
        }

      }
    },
    changeDiagnosticOncological(state, type) {
      if (type === 'create') {
        this.consul.oncologicalTretaments.molecular_diagnosis = state;
      } else {
        this.consultations.ClinicOncologicalTreatment.molecular_diagnosis = state;
      }

    },
    changeDiagnosticUpdate(state) {
      this.consultations.ClinicOrderStudy.include_diagnostic = state;
      if (!this.consultations.ClinicOrderStudy.diagnostic) {
        let resultName = []
        if (this.consultations.ClinicDiagnostics.length > 0) {
          for (const a of this.consultations.ClinicDiagnostics) {
            if (a.product) {
              resultName.push(a.product)
            }
          }
          if (resultName.length >= 0) {
            this.consultations.ClinicOrderStudy.diagnostic = resultName.map(item => `${item}`).join(',');
          }

        }
      }
    },
    async updateConsult() {
      this.show = true
      if (this.ClinicConsultationsFilesEdit.length > 0) {
        for (const a of this.ClinicConsultationsFilesEdit) {
          if (a.name && a.file) {
            this.consultations.ClinicConsultationsFiles.push({
              name: a.name,
              file: a.file,
              clinic_consultations_id: this.consultations.id,
            });
          }
        }
      } else {
        this.consultations.ClinicConsultationsFiles = [];
      }
      const response = await this.updateConsultationStore(this.consultations);

      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se edito correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        await this.viewConsultation(this.consultations.id);
        this.createConsultations = false;
        this.show = false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async deleteFileConsultant(id) {
      this.show = true;
      const response = await this.deleteFileRequest(id);
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se Borro el archivo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.show = false;
        await this.viewConsultation(this.consultations.id);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al borrar el archivo`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
    },
    async viewConsultation(id) {
      if(this.createConsultations){
        this.$swal({
          title: 'Desea continuar sin guardas?',
          text: "Tienes una consulta pendiente por guardar",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si continuar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.consultations.ClinicConsultationsFiles = [];
            this.ClinicConsultationsFilesEdit = [];
            await this.listAnswerTreatments();
            await this.listLineTreatments();
            await this.listAllTreatments();
            this.extra_select_laboratorie = {id: "", value: "Seleccione un laboratorio"}
            this.hospitals = {id: "", value: "Seleccione un hospital"}
            await this.getAllLaboratories();
            await this.listHospitalGets();
            this.consultations = {};
            this.updateInformation = false;
            this.createConsultations = false;
            this.consultations = await this.findPatientOneConsultations(id);
            let recipes = this.consultations.ClinicRecipes;
            let diagnostics = this.consultations.ClinicDiagnostics;
            let lines = this.consultations.ClinicOrderStudy.ClinicStudiesLines;

            let consultationsLines = this.consultations.ClinicConsultationsFiles;
            let oncologicalLinesGet;
            if (this.consultations.ClinicOncologicalTreatment) {
              oncologicalLinesGet = this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines;
            } else {
              oncologicalLinesGet = []
            }


            this.FilesDownloadList = [];
            this.consultations.ClinicRecipes = [];
            this.consultations.ClinicDiagnostics = [];
            if (this.consultations.ClinicOncologicalTreatment) {
              this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines = [];
            } else {
              const addLines = {
                ClinicOncologicalLines: []
              }
              this.consultations.ClinicOncologicalTreatment = addLines
            }

            this.consultations.ClinicConsultationsFiles = [];
            this.consultations.ClinicOrderStudy.ClinicStudiesLines = [];

            if (consultationsLines.length > 0) {
              this.ClinicConsultationsFilesEdit = [];
              const countSe = consultationsLines.length;
              this.totalCountFiles = countSe;
              const resNum = 1;
              for (const g of consultationsLines) {
                this.FilesDownloadList.push({
                  name: g.name,
                  id: g.id,
                });
              }
              for (var i = 0; i < resNum; i++) {
                this.ClinicConsultationsFilesEdit.push({
                  name: null,
                  file: null,
                  position: null,
                });
              }
            } else {
              this.ClinicConsultationsFilesEdit.push({
                name: null,
                file: null,
                position: null,
              });
            }

            for (const a of recipes) {
              this.consultations.ClinicRecipes.push({
                product: a.product,
                indications: a.indications,
                recipe_medicines_id: a.recipe_medicines_id,
                clinic_consultations_id: a.clinic_consultations_id,
                new_line: true,
              });
            }
            this.consultations.ClinicRecipes.push({
              product: null,
              indications: null,
              clinic_consultations_id: this.consultations.id,
              new_line: false,
            });
            if (diagnostics.length > 0) {
              for (const a of diagnostics) {
                this.consultations.ClinicDiagnostics.push({
                  product: a.name,
                  diagnostics_list_id: a.diagnostics_list_id,
                  clinic_consultations_id: a.clinic_consultations_id,
                  new_line: true,
                });
              }

            }
            this.consultations.ClinicDiagnostics.push({
              product: null,
              diagnostics_list_id: null,
              clinic_consultations_id: null,
              new_line: false,
            });
            if (oncologicalLinesGet.length > 0) {
              for (const a of oncologicalLinesGet) {
                this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
                  treatment: a.treatment,
                  description: a.description,
                  clinic_oncological_treatment_id: a.clinic_oncolo,
                  new_line: true,
                });
              }

            }
            this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
              treatment: null,
              description: null,
              clinic_oncological_treatment_id: this.consultations.ClinicOncologicalTreatment.id,
              new_line: false,
            });
            for (const c of lines) {
              this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
                product: c.product,
                indications: c.indications,
                clinic_order_studies_id: c.clinic_order_studies_id,
                study_lab_id: c.study_lab_id,
                new_line: true,
              });
            }
            this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
              product: null,
              indications: null,
              study_lab_id: null,
              clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
              new_line: false,
            });
            if (this.consultations.ClinicOrderStudy.list_laboratories_id) {
              const verifyLaboratories = await this.laboratoriesAll.find((a) => a.id === this.consultations.ClinicOrderStudy.list_laboratories_id);
              this.extra_select_laboratorie.value = verifyLaboratories.name;
              this.extra_select_laboratorie.id = verifyLaboratories.id;

            }
            if (this.consultations.hospital_list_id) {
              const verifyHospital = await this.listHospitals.find((a) => a.id === this.consultations.hospital_list_id);
              this.hospitals.value = verifyHospital.value;
              this.hospitals.id = verifyHospital.id;

            }
            if (this.consultations.ClinicOncologicalTreatment.line_treatment) {
              const verifyLines = await this.linesTreatments.find((a) => a.id === parseInt(this.consultations.ClinicOncologicalTreatment.line_treatment));

              this.lineTreatment.value = verifyLines.value;
              this.lineTreatment.id = verifyLines.id;

            }
            if (this.consultations.ClinicOncologicalTreatment.answer_treatment) {
              const verifyAnswers = await this.answerTreatments.find((a) => a.id === this.consultations.ClinicOncologicalTreatment.answer_treatment);
              this.answerTreatment.value = verifyAnswers.value;
              this.answerTreatment.id = verifyAnswers.id;

            }

            this.updateInformation = true;

          } else if (result.dismiss === 'cancel') {
          }
        })
      }else{
        this.consultations.ClinicConsultationsFiles = [];
        this.ClinicConsultationsFilesEdit = [];
        await this.listAnswerTreatments();
        await this.listLineTreatments();
        await this.listAllTreatments();
        this.extra_select_laboratorie = {id: "", value: "Seleccione un laboratorio"}
        this.hospitals = {id: "", value: "Seleccione un hospital"}
        await this.getAllLaboratories();
        await this.listHospitalGets();
        this.consultations = {};
        this.updateInformation = false;
        this.createConsultations = false;
        this.consultations = await this.findPatientOneConsultations(id);
        let recipes = this.consultations.ClinicRecipes;
        let diagnostics = this.consultations.ClinicDiagnostics;
        let lines = this.consultations.ClinicOrderStudy.ClinicStudiesLines;

        let consultationsLines = this.consultations.ClinicConsultationsFiles;
        let oncologicalLinesGet;
        if (this.consultations.ClinicOncologicalTreatment) {
          oncologicalLinesGet = this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines;
        } else {
          oncologicalLinesGet = []
        }


        this.FilesDownloadList = [];
        this.consultations.ClinicRecipes = [];
        this.consultations.ClinicDiagnostics = [];
        if (this.consultations.ClinicOncologicalTreatment) {
          this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines = [];
        } else {
          const addLines = {
            ClinicOncologicalLines: []
          }
          this.consultations.ClinicOncologicalTreatment = addLines
        }

        this.consultations.ClinicConsultationsFiles = [];
        this.consultations.ClinicOrderStudy.ClinicStudiesLines = [];

        if (consultationsLines.length > 0) {
          this.ClinicConsultationsFilesEdit = [];
          const countSe = consultationsLines.length;
          this.totalCountFiles = countSe;
          const resNum = 1;
          for (const g of consultationsLines) {
            this.FilesDownloadList.push({
              name: g.name,
              id: g.id,
            });
          }
          for (var i = 0; i < resNum; i++) {
            this.ClinicConsultationsFilesEdit.push({
              name: null,
              file: null,
              position: null,
            });
          }
        } else {
          this.ClinicConsultationsFilesEdit.push({
            name: null,
            file: null,
            position: null,
          });
        }

        for (const a of recipes) {
          this.consultations.ClinicRecipes.push({
            product: a.product,
            indications: a.indications,
            recipe_medicines_id: a.recipe_medicines_id,
            clinic_consultations_id: a.clinic_consultations_id,
            new_line: true,
          });
        }
        this.consultations.ClinicRecipes.push({
          product: null,
          indications: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
        if (diagnostics.length > 0) {
          for (const a of diagnostics) {
            this.consultations.ClinicDiagnostics.push({
              product: a.name,
              diagnostics_list_id: a.diagnostics_list_id,
              clinic_consultations_id: a.clinic_consultations_id,
              new_line: true,
            });
          }

        }
        this.consultations.ClinicDiagnostics.push({
          product: null,
          diagnostics_list_id: null,
          clinic_consultations_id: null,
          new_line: false,
        });
        if (oncologicalLinesGet.length > 0) {
          for (const a of oncologicalLinesGet) {
            this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
              treatment: a.treatment,
              description: a.description,
              clinic_oncological_treatment_id: a.clinic_oncolo,
              new_line: true,
            });
          }

        }
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
          treatment: null,
          description: null,
          clinic_oncological_treatment_id: this.consultations.ClinicOncologicalTreatment.id,
          new_line: false,
        });
        for (const c of lines) {
          this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
            product: c.product,
            indications: c.indications,
            clinic_order_studies_id: c.clinic_order_studies_id,
            study_lab_id: c.study_lab_id,
            new_line: true,
          });
        }
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: null,
          indications: null,
          study_lab_id: null,
          clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
          new_line: false,
        });
        if (this.consultations.ClinicOrderStudy.list_laboratories_id) {
          const verifyLaboratories = await this.laboratoriesAll.find((a) => a.id === this.consultations.ClinicOrderStudy.list_laboratories_id);
          this.extra_select_laboratorie.value = verifyLaboratories.name;
          this.extra_select_laboratorie.id = verifyLaboratories.id;

        }
        if (this.consultations.hospital_list_id) {
          const verifyHospital = await this.listHospitals.find((a) => a.id === this.consultations.hospital_list_id);
          this.hospitals.value = verifyHospital.value;
          this.hospitals.id = verifyHospital.id;

        }
        if (this.consultations.ClinicOncologicalTreatment.line_treatment) {
          const verifyLines = await this.linesTreatments.find((a) => a.id === parseInt(this.consultations.ClinicOncologicalTreatment.line_treatment));

          this.lineTreatment.value = verifyLines.value;
          this.lineTreatment.id = verifyLines.id;

        }
        if (this.consultations.ClinicOncologicalTreatment.answer_treatment) {
          const verifyAnswers = await this.answerTreatments.find((a) => a.id === this.consultations.ClinicOncologicalTreatment.answer_treatment);
          this.answerTreatment.value = verifyAnswers.value;
          this.answerTreatment.id = verifyAnswers.id;

        }

        this.updateInformation = true;
      }


    },
    changeLab(state) {
      this.consul.order_lab.recommend_lab = state;
    },
    changeHospital(state) {
      this.consul.is_hospitalization = state;
    },
    changeHospitalUpdate(state) {
      this.consultations.is_hospitalization = state;
    },
    changeLabUpdate(state) {
      this.consultations.ClinicOrderStudy.recommend_lab = state;
    },
    async createConsult() {
      this.show = true;
      this.consul.ClinicConsultationsFiles = [];
      if (this.ClinicConsultationsFiles.length > 0) {
        for (const a of this.ClinicConsultationsFiles) {
          if (a.name && a.file) {
            this.consul.ClinicConsultationsFiles.push({
              name: a.name,
              file: a.file,
            });
          }
        }
      } else {
        this.consul.ClinicConsultationsFiles = [];
      }
      this.consul.folio = dateFolio(this.data_patient.id);

      this.consul.appointee_id = this.data_patient.appointee_id;
      this.consul.patient_id = this.data_patient.id;
      const response = await this.createConsultation(this.consul);
      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.getConsultations();
        this.show = false;
        this.createConsultations = false;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },

    addRowMedicines(idx, type) {
      if (type === "create") {
        this.consul.recipes[idx].new_line = true;
        this.consul.recipes.push({
          product: null,
          indications: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicRecipes[idx].new_line = true;
        this.consultations.ClinicRecipes.push({
          product: null,
          indications: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeLineMedicines(id, type) {
      if (type === "create") {
        this.consul.recipes.splice(id, 1);
      } else {
        this.consultations.ClinicRecipes.splice(id, 1);
      }
    },
    addRowOncological(idx, type) {
      if (type === "create") {
        this.consul.oncologicalTretaments.oncologicalLines[idx].new_line = true;
        this.consul.oncologicalTretaments.oncologicalLines.push({
          treatment: null,
          description: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines[
            idx
            ].new_line = true;
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.push({
          treatment: null,
          description: null,
          clinic_oncological_treatment_id: this.consultations.ClinicOncologicalTreatment.id,
          new_line: false,
        });
      }
    },
    removeOncological(id, type) {
      if (type === "create") {
        this.consul.oncologicalTretaments.oncologicalLines.splice(id, 1);
      } else {
        this.consultations.ClinicOncologicalTreatment.ClinicOncologicalLines.splice(id, 1);
      }
    },
    addRowStudies(idx, type) {
      if (type === "create") {
        this.consul.order_lab.lines[idx].new_line = true;
        this.consul.order_lab.lines.push({
          study_lab_id: null,
          product: null,
          indications: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines[
            idx
            ].new_line = true;
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: null,
          indications: null,
          clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
          new_line: false,
        });
      }
    },
    removeStudies(id, type) {
      if (type === "create") {
        this.consul.order_lab.lines.splice(id, 1);
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.splice(id, 1);
      }
    },
    addRowDiagnostics(idx, type) {
      if (type === "create") {
        this.consul.diagnostics[idx].new_line = true;
        this.consul.diagnostics.push({
          diagnostics_list_id: null,
          product: null,
          new_line: false,
        });
      } else {
        this.consultations.ClinicDiagnostics[idx].new_line = true;
        this.consultations.ClinicDiagnostics.push({
          product: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false,
        });
      }
    },
    removeDiagnostics(id, type) {
      if (type === "create") {
        this.consul.diagnostics.splice(id, 1);
      } else {
        this.consultations.ClinicDiagnostics.splice(id, 1);
      }
    },
    async handleImage(id) {
      const file = document.querySelector(`#file${id}`).files[0];
      this.ClinicConsultationsFiles[id].name = file.name;
      const file_convert = await this.getBase64(file);
      file_convert.onload = (event) => {
        this.ClinicConsultationsFiles[id].file = event.target.result;
        this.ClinicConsultationsFiles[id].position = id;
      };
    },
    async handleImageEdit(id) {
      const file = document.querySelector(`#fileEdit${id}`).files[0];
      this.ClinicConsultationsFilesEdit[id].name = file.name;
      const file_convert = await this.getBase64(file);
      file_convert.onload = (event) => {
        this.ClinicConsultationsFilesEdit[id].file = event.target.result;
        this.ClinicConsultationsFilesEdit[id].position = id;
      };
    },
    async quitFile(id) {
      this.ClinicConsultationsFiles.splice(id, 1);
    },
    async quitFileEdit(id) {
      this.ClinicConsultationsFilesEdit.splice(id, 1);
    },
    async addInputFile() {
      if (this.ClinicConsultationsFiles.length > 4) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Solo puede subir maximo 5 archivos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.ClinicConsultationsFiles.push({
        name: null,
        file: null,
        position: null,
      });
    },
    async editInputFile() {
      const totalRows =
          this.totalCountFiles + this.ClinicConsultationsFilesEdit.length;
      if (totalRows > 4) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Solo puede subir maximo 5 archivos`,
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.ClinicConsultationsFilesEdit.push({
        name: null,
        file: null,
        position: null,
      });
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}
</style>
