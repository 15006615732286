<template>
  <div>
    <b-overlay :show="show" no-wrap/>
    <b-tabs style="margin-top: 30px">
      <b-tab title="Archivos aseguradoras" @click="initialize">
        <b-row class="mt-2 mb-3">
          <b-col lg="12" style="margin-bottom: 30px">
            <h2 style="text-align: center">{{ title }}</h2>
            <b-row v-if="listFiles.length > 0">
              <b-col cols="4" v-for="(a, idx) in listFiles" :key="idx" class="mt-2">
                <b-list-group>
                  <b-list-group-item style="cursor: pointer">
                    <b-row>
                      <b-col cols="6" @click="viewId(a.id)">
                        {{ a.name }} (ver archivo
                        {{ resHoursOdooFormatSix(a.createdAt) }})
                      </b-col>
                      <b-col cols="6">
                        <div align="right">
                          <a style="cursor: pointer" @click="deleteInsurers(a.id)">
                            <feather-icon
                                v-b-tooltip.hover
                                title="Borrar"
                                icon="TrashIcon"
                                style="color: red"
                                size="25"
                            />
                          </a>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row v-if="!informationFile" style="margin-top: 60px">
              <b-col lg="6">
                <b-form-select
                    v-model="selectType"
                    @input="pdfliCreate"
                    :options="optionsTypes"
                    size="sm"
                    class="mt-1"
                />
                <b-form-input
                    v-if="uploadFile"
                    style="margin-top: 20px"
                    v-model="nameDocument"
                    id="basicInput"
                    placeholder="Nombre del archivo"
                />
                <!--- <b-button
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="pdfliCreate()"
                  >
                    Generar pdf
                  </b-button>-->
              </b-col>
              <b-col lg="6">
                <div v-if="dataPdf">
                  <b-button
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="$refs.filePdf.click()"
                  >
                    Subir pdf
                  </b-button>
                  <input
                      type="file"
                      ref="filePdf"
                      @input="uploadPdf"
                      style="display: none"
                  />


                </div>
                <div v-if="uploadFile" style="margin-top: 20px">

                  <b-button
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="savePdfFile()"
                  >
                    Guardar pdf
                  </b-button>
                </div>
              </b-col>
              <b-col lg="12">
                <div v-if="dataPdf" style="margin-top: 20px; width: 100%">
                  <iframe style="width: 100%; height: 800px" :src="dataPdf">
                  </iframe>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="informationFile" style="margin-top:60px">
              <b-col lg="12" v-if="viewOne">
                <b-row>
                  <b-col lg="6">
                    <b-button
                        style="width: 100%"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="$refs.filePdfUpload.click()"
                    >
                      Subir pdf
                    </b-button>
                    <input
                        type="file"
                        ref="filePdfUpload"
                        @input="uploadUpdatePdf"
                        style="display: none"
                    />
                    <div v-if="update" style="margin-top: 20px">
                      <b-button
                          style="width: 100%"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                          @click="updatePdfFile()"
                      >
                        Guardar Cambios
                      </b-button>
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <b-button
                        style="width: 100%"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="button"
                        variant="primary"
                        class="mr-1"
                        @click="returnNewFile()"
                    >
                      Subir Otra aseguradora
                    </b-button>
                    <b-form-input
                        style="margin-top: 20px"
                        v-model="nameDocument"
                        placeholder="Nombre del archivo"
                    />
                  </b-col>
                </b-row>
                <iframe style="width: 100%; height: 800px;margin-top:30px" :src="viewOne">
                </iframe>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Pagos directos de aseguradoras" @click="chargeFiles">
        <b-modal
            hide-footer
            id="modal-prevent-closing-all-file"
            ref="my-modal"
            :title="`Subir Archivo`"
            ok-title="Guardar"
            cancel-title="Cerrar"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Titulo"
              >
                <b-form-input
                    v-model="file_send.title"
                    placeholder="Titulo"
                />
              </b-form-group>

            </b-col>

            <b-col cols="12">
              <b-form-group
                  label="Archivo"
              >
                <b-form-file
                    @change="handleImage()"
                    id="file_patient"
                    placeholder="Subir archivo."
                    drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">

              <VoiceInput label="Comentario" placeholder="Comentario" rows="3" id="textarea-comment"
                          v-model="file_send.comment"/>

            </b-col>
            <b-col cols="12">
              <b-button
                  @click="createFile"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Guardar
              </b-button>

            </b-col>

          </b-row>
        </b-modal>
        <b-row class="mt-2 mb-3">
          <b-col
              lg="12"
          >
            <h2 style="text-align: center">{{ title }}</h2>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-3" lg="12" v-if="files.length === 0">
          <b-col

          >
            <h3 style="text-align: center">Aún no has subido ningún archivo</h3>
            <h4 style="text-align: center">Sube tu primer Archivo Haciendo Click en el botón</h4>
            <div align="center">

              <b-button
                  @click="showModalFiles"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Subir Archivo
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-row v-else>
          <b-col
              lg="3"
          >
            <b-button
                @click="showModalFiles"
                style="width: 200px"
                class="mt-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
            >
              Subir Archivo
            </b-button>
            <div class="mt-3">
              <b-list-group>
                <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in files" :key="idx"
                                   @click="showOneFile(a.id)">
                  <b-row>
                    <b-col lg="12">
                      <div align="center">
                        <feather-icon icon="FolderIcon" size="40"/>
                        <strong style="margin-left: 10px">Titulo: </strong>{{ a.title }}
                      </div>
                    </b-col>
                    <b-col lg="12">
                      <p style="text-align: center"><strong>Comentarios:</strong> <br>
                        {{ a.comment }} <br>
                      </p>
                      <p style="text-align: center">
                        {{ resHoursOdooFormatSix(a.createdAt) }}</p> <br>
                      <p style="text-align: center">
                        Empleado: {{ a.HrEmployee.name }}</p>

                    </b-col>
                  </b-row>

                </b-list-group-item>
              </b-list-group>
            </div>

          </b-col>
          <b-col cols="9" v-if="viewFile">
            <br>
            <b-row>
              <b-col cols="4">
                <div align="center">
                  <h3>Información</h3><br>
                  <h4>Nombre: <strong>{{ one.title }}</strong></h4>
                  <h4>Comentario: <strong>{{ one.comment }}</strong></h4>
                  <h4>Empleado: <strong>{{ one.HrEmployee.name }}</strong></h4>
                </div>
              </b-col>
              <b-col cols="4">
                <div align="center">
                  <h3>Descargar Archivo</h3>

                  <a @click="downloads(one.id, one.file,one.title, data_patient.name)"
                     style="text-align: center">
                    <feather-icon icon="FolderIcon" size="100"/>
                    <br>
                    {{ one.file }}
                  </a>

                </div>
              </b-col>
              <b-col cols="4">
                <div align="center">
                  <h3>Borrar archivo: </h3>
                  <a style="text-align: right" @click="deleteFile(one.id)">
                    <feather-icon icon="TrashIcon" style="color: darkred" size="50"/>
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BFormFile,
  BForm,
  BFormSelect,
  BFormRadio,
  BFormCheckbox,
  BOverlay,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BTabs, BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import moment from "moment";
import {PDFDocument} from "pdf-lib";
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "InsurersPatient",
  components: {
    VoiceInput,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BOverlay,
    VBTooltip,
    BListGroup,
    BListGroupItem,
    BTabs, BTab,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    title: String,
    data_patient: Object,
  },

  data() {
    return {
      dataPdf: null,
      update: false,
      informationFile: null,
      nameDocument: null,
      viewOne: null,
      listFiles: [],
      uploadFile: null,
      selectType: null,
      listFilesAll: null,
      show: false,
      optionsTypes: [],
      user: {},
      patient_id: "",
      general_information: {
        id: "",
        important_background: null,
        allergies: null,
        refers_patient: null,
        reason: null,
        observations: null,
        patient_id: null,
      },
      file_send: {
        id: null,
        title: null,
        name_file: null,
        comment: null,
        file: null,
        patient_id: 0
      },
      files: [],
      viewFile: false,
      history: {
        id: null,
        diabetes_mellitus: false,
        diabetes_observations: "",
        cancer: false,
        cancer_observations: "",
        date_rule_no: moment().format("YYYY-MM-DD"),
        cancer_personal: false,
        cancer_personal_observations: "",
        arterial_hypertension: false,
        arterial_hypertension_observations: "",
        pathological_observations: "",
        drug_intake: false,
        drug_intake_observations: "",
        surgeries: false,
        surgeries_observations: "",
        transfusions: false,
        transfusions_observations: "",
        hepatitis: false,
        hepatitis_type: "",
        hepatitis_observations: "",
        diatebes_personal: false,
        diabetes_personal_observations: "",
        arterial_personal: false,
        arterial_personal_observations: "",
        other_diseases: "",
        blood_group: "",
        rh: "",
        smoking: false,
        smoking_observations: "",
        medicines: false,
        medicines_observations: "",
        alcoholism: false,
        alcoholism_week: "",
        alcoholism_day: "",
        exercise: false,
        exercise_observations: "",
        menarche: null,
        gestations: false,
        childbirth: null,
        caesarean: null,
        abortions: null,
        deaths: null,
        ectopic: null,
        date_gestations: moment().format("YYYY-MM-DD"),
        comments: "",
        comments_menarche: "",
        continue_menstruating: false,
        duration_menstruating: null,
        frequency_menstruating: null,
        irregular_menstrual: false,
        contraceptives: false,
        date_rule: moment().format("YYYY-MM-DD"),
        patient_id: false,
      },
    };
  },
  mounted() {
    this.$root.$on("chargeInsurers", async () => {
      this.patient_id = this.data_patient.id;
      this.dataPdf = null;
      this.informationFile = null;
      this.optionsTypes = [];
      this.update = false;
      this.viewOne = null;
      this.user = this.data_patient;
      this.selectType = null
      await this.verifyInfo();
      await this.getTypes();
      await this.verifyHistory();
      await this.getLists();
    });
  },

  methods: {
    ...mapActions('insurance_files', ['findPatientAllFiles', 'createPatientFiles', 'findPatientOneFiles', 'downloadFiPatientOne', 'updateFileHistory']),
    ...mapActions("clinic_patient", ["findHistory", "createHistory"]),
    ...mapActions("patient", [
      "findPatientOneInformation",
      "createInformationGeneral",
      "updateInformationGeneral",
      "findPatientOneHistory",
      "findAllTypeInsurance",
      "createInsuranceFile",
      "createHistoryMedical",
      "updateHistoricPatient",
      "findAllInsurers",
      "getFindFullName",
      "findOneInsurers", "downloadS3Aws", "updateInsuranceFile", 'deleteOneInsurers'
    ]),

    async chargeFiles() {
      this.viewFile = false
      await this.findFiles();
    },
    async downloads(id, file, name, partner) {
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
      }
      await this.downloadFiPatientOne(send);
    },
    async findFiles() {
      this.files = await this.findPatientAllFiles({id: this.data_patient.id});
    },
    async showOneFile(id) {
      const response = await this.findPatientOneFiles({id: id, patient: this.data_patient.id});
      if (response) {
        this.one = response
        this.viewFile = true
      }
    },
    async deleteFile(id) {
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres borrar el archivo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const send = {
            is_deleted: true
          }
          const response = await this.updateFileHistory({id: id, data: send});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Borro!',
              text: 'Su archivo se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findFiles()
            this.show = false
            this.viewFile = false
            this.file_send = {
              title: null,
              name_file: null,
              comment: null,
              file: null,
              patient_id: this.patient_id
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar archivo consulto con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },
    async createFile() {
      this.file_send.patient_id = this.data_patient.id;
      if (!this.file_send.file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Archivo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }

      if (!this.file_send.title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'titulo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      await this.$bvModal.hide('modal-prevent-closing-all-file')
      this.show = true
      const response = await this.createPatientFiles(this.file_send)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se guardo el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        await this.findFiles()
        this.file_send = {
          id: null,
          title: null,
          comment: null,
          file: null,
          patient_id: 0
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al subir archivo consulte al administrador',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
        await this.$bvModal.show('modal-prevent-closing-all-file')
      }

    },
    async handleImage() {
      const file = document.querySelector(`#file_patient`).files[0]

      const file_convert = await this.getBase64Img(file)
      file_convert.onload = (event) => {
        this.file_send.file = event.target.result;
        this.file_send.name_file = file.name
      };
    },
    async getBase64Img(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async showModalFiles() {
      await this.$bvModal.show('modal-prevent-closing-all-file')


    },
    resetModal() {
    },
    async initialize() {
      await this.verifyInfo();
      await this.getTypes();
      await this.verifyHistory();
      await this.getLists();
    },
    async viewId(id) {
      this.informationFile = await this.findOneInsurers(id);
      this.nameDocument = this.informationFile.name

      const response = await this.downloadS3Aws(this.informationFile.name);
      this.viewOne = `data:application/pdf;base64,${response}`


    },
    async deleteInsurers(id) {
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres borrar el archivo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const send = {
            is_deleted: true
          }
          const response = await this.deleteOneInsurers(id);
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Borro!',
              text: 'Su archivo se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.dataPdf = null;
            this.informationFile = null;
            this.optionsTypes = [];
            this.update = false;
            this.viewOne = null;
            this.selectType = null;
            this.show = false;
            this.uploadFile = null;
            await this.getLists();
            await this.getTypes();
            this.show = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar archivo consulto con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })


    },
    async returnNewFile() {
      this.dataPdf = null;
      this.informationFile = null;
      this.optionsTypes = [];
      this.update = false;
      this.uploadFile = null;
      this.selectType = null;
      this.viewOne = null;
      this.show = false;
      await this.getLists();
      await this.getTypes();
    },
    async updatePdfFile() {
      this.show = true;
      if (!this.informationFile.insurers_id) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aseguradora requerida",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.informationFile.file) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Archivo requerido requerida",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.nameDocument) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Nombre del archivo requerido",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      const send = {
        id: this.informationFile.id,
        patient_id: this.informationFile.patient_id,
        insurers_id: this.informationFile.insurers_id,
        file: this.informationFile.file,
        name: this.nameDocument,
      };

      const response = await this.updateInsuranceFile(send);
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se edito el archivo correctamente",
            icon: "success",
            variant: "success",
          },
        });

        this.dataPdf = null;
        this.informationFile = null;
        this.optionsTypes = [];
        this.update = false;
        this.viewOne = null;
        this.uploadFile = null;
        this.selectType = null;
        this.show = false;
        this.nameDocument = null
        await this.getLists();
        await this.getTypes();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error al subir archivo consulte al administrador",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async savePdfFile() {
      this.show = true;
      if (!this.selectType) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aseguradora requerida",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.uploadFile) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Archivo requerido requerida",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.nameDocument) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "El nombre del archivo es requerido",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        return;
      }
      const send = {
        patient_id: this.patient_id,
        insurers_id: this.selectType,
        file: this.uploadFile,
        name: this.nameDocument,
      };

      const response = await this.createInsuranceFile(send);
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se subio el archivo correctamente",
            icon: "success",
            variant: "success",
          },
        });
        this.dataPdf = null;
        this.informationFile = null;
        this.optionsTypes = [];
        this.update = false;
        this.viewOne = null;
        this.nameDocument = null
        this.show = false;
        this.uploadFile = null;
        this.selectType = null;
        await this.getLists();
        await this.getTypes();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error al subir archivo consulte al administrador",
            icon: "EditIcon",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async uploadPdf(event) {
      const file = event.target.files[0];
      const file_convert = await this.getBase64(file);
      file_convert.onload = async (event) => {
        this.uploadFile = event.target.result;
        this.dataPdf = event.target.result;
      };
    },
    async uploadUpdatePdf(event) {
      const file = event.target.files[0];
      const file_convert = await this.getBase64(file);
      file_convert.onload = async (event) => {
        this.informationFile.file = event.target.result;
        this.viewOne = event.target.result;
        this.update = true;
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    },
    async getTypes() {
      const response = await this.findAllTypeInsurance();
      this.listFilesAll = response
      this.optionsTypes.push({
        value: null,
        text: "Seleccione una aseguradora",
      });
      for (const a of response) {
        this.optionsTypes.push({
          value: a.id,
          text: a.name,
        });
      }
    },
    async getLists() {
      const response = await this.findAllInsurers(this.patient_id);

      this.listFiles = response;
    },
    async pdfliCreate() {
      if (!this.selectType) return;
      const verifySelect = this.listFilesAll.find((l) => l.id === this.selectType);
      const formUrl = verifySelect.file;
      this.show = true
      const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(formBytes);

      const form = pdfDoc.getForm();
      let doctorName = '';
      let nameFComplete = '';
      let permit_no;
      let study_field;
      let fullDoctor = '';
      let doctorFirst = '';
      let doctorMiddle = '';
      let doctorLast = '';
      let singanatureFirm = '';
      let pngDims;
      let pngImage;
      const userData = this.getUser;
      if (userData.HrDepartment.name === 'MEDICOS') {
        const responseName = await this.getFindFullName(userData.id);

        const dr = responseName.info.name;
        permit_no = responseName.signature.permit_no ? responseName.signature.permit_no : '';
        study_field = responseName.signature.study_field ? responseName.signature.study_field : '';
        const separateCom = dr.split('.')
        singanatureFirm = responseName.signatureFile ? responseName.signatureFile : null

        if (singanatureFirm) {
          pngImage = await pdfDoc.embedPng('data:image/png;base64,' + singanatureFirm);
          pngDims = pngImage.scale(0.25);
        }
        if (separateCom.length > 0) {
          doctorName = separateCom[1];
          const doctorSeparate = doctorName.split(" ");
          if (doctorSeparate.length >= 5) {
            fullDoctor = doctorSeparate[3] + ' ' + doctorSeparate[4] + ' ' + doctorSeparate[2] + ' ' + doctorSeparate[1]
          }
          if (doctorSeparate.length >= 4 && doctorSeparate.length <= 4) {
            fullDoctor = doctorSeparate[2] + ' ' + doctorSeparate[3] + ' ' + doctorSeparate[1]

          }
          if (doctorSeparate.length >= 3 && doctorSeparate.length <= 3) {
            fullDoctor = doctorSeparate[2] + ' ' + doctorSeparate[1]
          }

          if (doctorSeparate.length >= 5) {
            doctorFirst = doctorSeparate[1] + ' ' + doctorSeparate[2]
            doctorMiddle = doctorSeparate[3];
            doctorLast = doctorSeparate[4];
          }
          if (doctorSeparate.length >= 4 && doctorSeparate.length <= 4) {
            doctorFirst = doctorSeparate[1]
            doctorMiddle = doctorSeparate[2];
            doctorLast = doctorSeparate[3];
          }
          if (doctorSeparate.length >= 3 && doctorSeparate.length <= 3) {
            doctorFirst = doctorSeparate[1]
            doctorMiddle = doctorSeparate[2];
            doctorLast = '';
          }

        } else {
          doctorName = dr
        }
      } else {
        permit_no = '';
        study_field = '';
        doctorName = ''
      }
      let nameF;
      let middleName;
      let lastName;
      const nameSeparate = this.user.name.split(" ")
      if (nameSeparate.length >= 4) {
        nameF = nameSeparate[0] + ' ' + nameSeparate[1]
        middleName = nameSeparate[2];
        lastName = nameSeparate[3];

      }
      if (nameSeparate.length >= 3 && nameSeparate.length <= 3) {
        nameF = nameSeparate[0]
        middleName = nameSeparate[1];
        lastName = nameSeparate[2];
      }
      if (nameSeparate.length >= 2 && nameSeparate.length <= 2) {
        nameF = nameSeparate[0]
        middleName = nameSeparate[1];
        lastName = '';
      }
      if (nameSeparate.length >= 4) {
        nameFComplete = nameSeparate[2] + ' ' + nameSeparate[3] + ' ' + nameSeparate[0] + ' ' + nameSeparate[1]


      }
      if (nameSeparate.length >= 3 && nameSeparate.length <= 3) {
        nameFComplete = nameSeparate[1] + ' ' + nameSeparate[2] + ' ' + nameSeparate[0]

      }
      if (nameSeparate.length >= 2 && nameSeparate.length <= 2) {
        nameFComplete = nameSeparate[1] + ' ' + nameSeparate[0]

      }


      if (verifySelect.name === 'GNP') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 400,
            y: 80,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const years = this.yearsCalculate(birthday);

          const yearField = form.getTextField("Edad");
          const Text2 = form.getTextField("Text2");
          const Text3 = form.getTextField("Text3");
          const Text4 = form.getTextField("Text4");
          const Text5 = form.getTextField("Text5");
          const Text6 = form.getTextField("Text6");
          const Text7 = form.getTextField("Text7");
          const Text8 = form.getTextField("Text8");
          const Text9 = form.getTextField("Text9");
          yearField.setText(years.toString());
          Text2.setText(newBirthday[0]);
          Text3.setText(newBirthday[1]);
          Text4.setText(newBirthday[2]);
          Text5.setText(newBirthday[3]);
          Text6.setText(newBirthday[4]);
          Text7.setText(newBirthday[5]);
          Text8.setText(newBirthday[6]);
          Text9.setText(newBirthday[7]);
        }

        if (this.user.gender) {
          if (this.user.gender === 'M') {
            form.getRadioGroup("Group2").select('Opción1')
          } else {
            form.getRadioGroup("Group2").select('Opción2')
          }
        }
        const doctorSeparate = doctorName.split(" ");


        const nameField = form.getTextField(
            "Apellido Paterno Apellido Materno Nombres"
        );
        const nameDoctor = form.getTextField(
            "Text52"
        );

        form.getTextField(
            "Especialidad"
        ).setText(study_field);
        form.getTextField(
            "Cédula profesional"
        ).setText(permit_no);
        form.getTextField(
            "Nombre y firma del Médico tratante"
        ).setText(doctorName);

        nameField.setText(this.user.name);
        nameDoctor.setText(fullDoctor);
      } else if (verifySelect.name === 'AXA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[4];
          firstPage.drawImage(pngImage, {
            x: 80,
            y: 480,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const date1 = form.getTextField("patient.birth_date");
          date1.setText(newBirthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );

        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctor.setText(doctorName);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'ALLIANZ') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 450,
            y: 260,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );
        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "Text109"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctor.setText(doctorName);
        nameDoctorFull.setText(doctorName);
        nameFirst.setText(lastName);
        nameMiddle.setText(middleName);
        nameLast.setText(nameF);
      } else if (verifySelect.name === 'MONTERREY') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 270,
            y: 110,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );

        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'ATLAS') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 460,
            y: 170,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const myArray = birthday.split("-");
          const years = this.yearsCalculate(birthday);
          const yearSeparate = myArray[0];
          const s = yearSeparate.split('');

          const newBirthday = myArray[2] + "" + myArray[1] + "" + s[2] + s[3];

          const date1 = form.getTextField("patient.birth_date");
          date1.setText(newBirthday);


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameField = form.getTextField(
            "patient.fullName"
        );
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );
        const nameDoctorFullSig = form.getTextField(
            "signature.patientFullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        form.getTextField(
            "Firma del médico tratante"
        ).setText(doctorName);
        nameField.setText(this.user.name);
        nameDoctorFullSig.setText(this.user.name);
        nameDoctor.setText(doctorName);
      } else if (verifySelect.name === 'BBVA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 60,
            y: 50,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'MAPFRE') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 460,
            y: 150,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "Firma"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor.setText(doctorName);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'METLIFE') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[3];
          firstPage.drawImage(pngImage, {
            x: 320,
            y: 70,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);

          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameField = form.getTextField(
            "patient.fullName"
        );
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );
        const nameDoctorFullSig = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameField.setText(this.user.name);
        nameDoctorFullSig.setText(doctorName);
        nameDoctor.setText(doctorName);
      } else if (verifySelect.name === 'INBURSA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 460,
            y: 65,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);

          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameField = form.getTextField(
            "patient.fullName"
        );
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );

        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        form.getTextField(
            "FIRMA DEL MÉDICO TRATANTE"
        ).setText(doctorName);
        nameField.setText(this.user.name);
        nameDoctor.setText(doctorName);
      } else if (verifySelect.name === 'BXMAS') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 280,
            y: 390,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'BANAMEX') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 440,
            y: 95,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );
        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor.setText(doctorName);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'MULTIVA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 340,
            y: 60,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'ZURICH') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 510,
            y: 40,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor.setText(doctorName);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'LATINOSEGUROS') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 420,
            y: 50,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.name"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'PLANSEGURO') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 100,
            y: 75,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());

        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()

          } else {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()
          }
        }
        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);
        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
      } else if (verifySelect.name === 'PREVEM') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 290,
            y: 70,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.fullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'SISNOVA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 430,
            y: 50,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.fullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'PANAMERICAN MEXICO') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 80,
            y: 700,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "/" + myArray[1] + "/" + myArray[0];
          form.getTextField('patient.birth_date').setText(newBirthday)
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.fullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        form.getTextField(
            "signature.medicName"
        ).setText(fullDoctor);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'TELMEX') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 320,
            y: 50,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getTextField("patient.gender").setText('Masculino');

          } else {
            form.getTextField("patient.gender").setText('Femenino');
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.fullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(doctorName);
        fullName.setText(this.user.name);

      } else if (verifySelect.name === 'GENERAL SALUD') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 445,
            y: 20,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
          const myArray = birthday.split("-");
          form.getTextField("patient.birth_date.day").setText(myArray[2]);
          form.getTextField("patient.birth_date.month").setText(myArray[1]);
          form.getTextField("patient.birth_date.year").setText(myArray[0]);
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor1 = form.getTextField(
            "medic.name"
        );
        const nameDoctor2 = form.getTextField(
            "medic.lastF"
        );
        const nameDoctor3 = form.getTextField(
            "medic.lastM"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);

        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
        nameDoctorFull.setText(doctorName);
        nameDoctor1.setText(doctorFirst);
        nameDoctor2.setText(doctorMiddle);
        nameDoctor3.setText(doctorLast);

      } else if (verifySelect.name === 'BANORTE') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 445,
            y: 10,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const newBirthday = myArray[2] + "" + myArray[1] + "" + myArray[0];
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());


        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );
        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "signature.medicName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        nameDoctor.setText(fullDoctor);
        fullName.setText(nameFComplete);

      } else if (verifySelect.name === 'BUPA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[2];
          firstPage.drawImage(pngImage, {
            x: 480,
            y: 20,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const myArray = birthday.split("-");
          const yearTwo = myArray[0].split('');
          const newBirthday = myArray[2] + myArray[1] + yearTwo[2] + yearTwo[3];

          form.getTextField("patient.birth_date").setText(newBirthday);
        }
        if (this.user.email) {
          form.getTextField("patient.mail").setText(this.user.email);
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.femaile").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.femaile").check()
          }
        }

        const nameFirst = form.getTextField(
            "patient.name"
        );
        const nameMiddle = form.getTextField(
            "patient.lastF"
        );
        const nameLast = form.getTextField(
            "patient.lastM"
        );
        const nameDoctor = form.getTextField(
            "medic.fullName"
        );

        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);

        nameFirst.setText(nameF);
        nameMiddle.setText(middleName);
        nameLast.setText(lastName);
        nameDoctor.setText(fullDoctor);

      } else if (verifySelect.name === 'SURA') {
        if (singanatureFirm) {
          const pages = pdfDoc.getPages();
          const firstPage = pages[1];
          firstPage.drawImage(pngImage, {
            x: 360,
            y: 20,
            width: pngDims.width,
            height: pngDims.height,
          })
        }

        if (this.user.birthday) {
          const birthday = this.user.birthday;
          const years = this.yearsCalculate(birthday);
          const yearField = form.getTextField("patient.age");
          yearField.setText(years.toString());
        }
        if (this.user.gender) {

          if (this.user.gender === 'M') {
            form.getCheckBox("patient.male").check()
            form.getCheckBox("patient.female").uncheck()

          } else {
            form.getCheckBox("patient.male").uncheck()
            form.getCheckBox("patient.female").check()
          }
        }

        const fullName = form.getTextField(
            "patient.fullName"
        );
        const nameDoctorFull = form.getTextField(
            "medic.fullName"
        );
        form.getTextField(
            "medic.specialty.name"
        ).setText(study_field);
        form.getTextField(
            "medic.certificate"
        ).setText(permit_no);
        nameDoctorFull.setText(fullDoctor);
        fullName.setText(nameFComplete);

      }

      try {
        const pdfBytes = await pdfDoc.saveAsBase64();
        this.dataPdf = `data:application/pdf;base64,${pdfBytes}`;
        this.show = false
        // var blob=new Blob([pdfBytes], {type: "application/pdf"});// change resultByte to bytes
        // var link=document.createElement('a');
        // link.href=window.URL.createObjectURL(blob);
        // link.download="myFileName.pdf";
        // link.click();
      } catch (error) {
        this.show = false
        console.log(error);
      }

      // await this.saveAndRender(await PDFDocument.load(formBytes))
    },
    async pdfGnp() {

    },

    async verifyInfo() {
      const response = await this.findPatientOneInformation(
          this.data_patient.id
      );

      if (response) {
        this.general_information = response;
      } else {
        delete this.general_information.id;
        this.general_information.patient_id = this.data_patient.id;
        await this.createInformationGeneral(this.general_information);
        await this.verifyInfo();
      }
    },
    async verifyHistory() {
      const response = await this.findPatientOneHistory(this.data_patient.id);
      if (response) {
        this.history = response;
      } else {
        delete this.history.id;
        this.history.patient_id = this.data_patient.id;
        await this.createHistoryMedical(this.history);
        await this.verifyHistory();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  }
};
</script>

<style scoped></style>
