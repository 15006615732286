import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/es';
dayjs.locale('es');
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(updateLocale);
dayjs.updateLocale('es', {
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
});

export function dateToday(format = 'YYYY-MM-DD ') {
    return dayjs().format(format);
}


export function getFormat(value, format = 'DD/MM/YYYY') {
    return dayjs(value).format(format);
}

export function formatHour(value, format = 'DD/MM/YYYY HH:mm') {
    return dayjs(value).format(format);
}

export function sumMonths(value,month,format = 'DD/MM/YYYY') {
    return dayjs(value).add(month, 'month').format(format);
}

export function dateStringMonth(format = 'DD/MM/YYYY HH:mm') {
    return dayjs().format(format);
}

export function sumMonthsHours(value,month,format = 'DD/MM/YYYY HH:mm') {
    return dayjs(value).add(month, 'month').format(format);
}

export function sumMonthsformat(value,month,format = 'YYYY-MM-DD') {
    return dayjs(value).add(month, 'month').format(format);
}

export function dateTodayFormat(format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs().format(format);
}

export function dateFolio(user) {
    return dayjs().format('YYYYMMDD-HHmmss')+'-'+user;
}